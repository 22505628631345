import * as ModelEvent from "@node-elion/syncron";
import { compareDesc } from "date-fns";

import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Language from "../Language";
import Subscription from "../../types/Subscription";
import Base from "../Base";
import Card from "../Card";
import TaxiService from "../TaxiService";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import Main from "../../pages/Settings/pages/Tariffs/tabs/Auto/components/Modal/components/Content/tabs/Main";
import Rules from "../../pages/Settings/pages/Tariffs/tabs/Auto/components/Modal/components/Content/tabs/Main/components/Rules";
import { ModelId } from "../../types/ModelId";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import { Units } from "../../pages/Settings/pages/Tariffs/constants/constants";

import { ruleFromResponse, ruleToRequest } from "./utils";

class AutoTariff extends Base {
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;
		this._Card = new Card((prpc) => prpc.theirsModel.autoRate.card);
		return this._Card;
	}

	public static fromResponse(data: any): AutoTariff.Model {
		const taxiServices = data.autoRateToTaxiServices.flatMap(
			(item) => item.taxiService,
		);

		const { units } = data.additionalFields.general.hourlyServiceInterval;
		const externalValue =
			data.additionalFields.general.hourlyServiceInterval.value;

		const value =
			units === "hours"
				? externalValue / 1000 / 3600
				: externalValue / 1000 / 60;

		const rules: any[] = data.rules.map(ruleFromResponse);
		const sortRules = rules.sort((from, to) => {
			const sort = compareDesc(
				new Date(to.position ?? 0),
				new Date(from.position ?? 0),
			);
			return sort;
		});

		return {
			id: data.id,

			name: data.name,
			active: data.active,
			disableOthers: data.disableOthers,
			additionalFields: {
				...data.additionalFields,
				general: {
					...data.additionalFields.general,
					hourlyServiceInterval: {
						...data.additionalFields.general.hourlyServiceInterval,
						value,
					},
				},
			},
			rules: sortRules,
			taxiServices,
			taxiServiceIds: taxiServices.map(({ id }) => id),
			position: new Date(data.position).getTime(),

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(model: AutoTariff.New | AutoTariff.Modified) {
		const general = model.additionalFields?.general;
		const units = general?.hourlyServiceInterval?.units;
		const externalValue = general?.hourlyServiceInterval.value ?? 0;
		const value =
			units === Units.HOURS
				? externalValue * 1000 * 3600
				: externalValue * 1000 * 60;

		const rules = model.rules?.map(ruleToRequest);
		console.log("[AutoTariff] rules", { rules, model });

		return {
			name: model.name,
			active: model.active,
			disableOthers: model.disableOthers,
			additionalFields: model.additionalFields
				? {
						...model.additionalFields,
						general: {
							...general,
							hourlyServiceInterval: {
								...general?.hourlyServiceInterval,
								value,
							},
						},
				  }
				: undefined,
			rules,
			taxiServiceIds: model.taxiServiceIds,
		};
	}

	public static async getAll() {
		const res = await this.request((prpc) =>
			prpc.theirsModel.autoRate.getAll(),
		);
		return res?.items?.map(this.fromResponse);
	}

	public static async rearrange(id: ModelId, position: number) {
		try {
			console.log("[AutoTariff] rearrange", { id, position });
			await this.request((prpc) =>
				prpc.theirsModel.autoRate.rearrange(id, position),
			);
		} catch (error: any) {
			console.error("Error in rearrange method:", error);
		}
	}

	public static async copy(id: ModelId) {
		try {
			await this.request((prpc) => prpc.theirsModel.autoRate.copy(id));
		} catch (err: any) {
			throw new Error("Error in copy method:", err);
		}
	}

	public static async store(object: AutoTariff.New, force = false) {
		try {
			console.log("[AutoTariff] store", object);
			await this.request((prpc) =>
				prpc.theirsModel.autoRate.create(this.toRequest(object), force),
			);
		} catch (err: any) {
			if (err.status === 400 && err.message.includes("force")) {
				return false;
			}
		}
		return true;
	}

	public static async update(object: AutoTariff.Modified, force = false) {
		try {
			console.log("[AutoTariff] update", object);
			await this.request((prpc) =>
				prpc.theirsModel.autoRate.update(
					object.id,
					this.toRequest(object),
					force,
				),
			);
		} catch (err: any) {
			if (err.status === 400 && err.message.includes("force")) {
				return false;
			}
		}
		return true;
	}

	public static async delete(id: number[] | number) {
		this.request((prpc) => prpc.theirsModel.autoRate.delete(id));
	}

	public static async subscribe(
		options: AutoTariff.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<AutoTariff.Model>,
	): Promise<Subscription<AutoTariff.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[AutoTariff] subscribe", { state, options });
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.autoRate.subscribe({
					params: options,
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						console.error(error);
					},
				}),
			{ name: "AutoTariff.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: AutoTariff.SubscribeOptions) =>
				subscription.update(options),
		} as Subscription<AutoTariff.SubscribeOptions>;
	}
}

declare namespace AutoTariff {
	interface Model extends NonEditableProperties {
		name: string;
		active: boolean;
		disableOthers: boolean;
		additionalFields: Main.AdditionalFields;
		taxiServices: TaxiService.Model[];
		taxiServiceIds: number[];
		rules: Rules.Rule[];
		position: number;
	}

	type New = Omit<Model, NonEditablePropertyNames>;
	type Modified = Partial<New> &
		Partial<Omit<NonEditableProperties, "id">> & { readonly id: number };

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<AutoTariff.Model> {
		active?: boolean;
		language?: Language;
		taxiServiceIds?: number[];
	}
}

export default AutoTariff;
