import styled from "styled-components";
import { theme } from "uikit";

const Divider = styled.div`
	// position: absolute;
	top: 0;
	bottom: 0;
	width: 1px;
	background: ${theme.colors.button_secondary};
`;

export default Divider;
