import React, { Children, forwardRef, memo, useMemo } from "react";
import { extend } from "lodash";

import { useTypedSelector } from "../../redux/store";
import { AccessKey, hasAccess, generateAccessName } from "../../access";

import Root from "./components/Root";

export * from "./hooks/useTableOptions";
export { default as CellContentRoot } from "./components/CellContentRoot";

const LightTableBase = forwardRef<LightTable.Ref, LightTable.Props>(
	({ children, onRowDoubleClick, accessName, ...props }, ref) => {
		const personalRole = useTypedSelector(
			(state) => state.account.personalRole,
		);

		const AccessBtnGroup = useMemo(() => {
			const ACCESS_IS_ALLOWED_BY_DEFAULT = true;

			const retval = {
				edit: accessName
					? hasAccess(
							generateAccessName(
								...accessName,
								AccessKey.CONTROL_BUTTON_EDIT,
							),
							personalRole,
					  )
					: ACCESS_IS_ALLOWED_BY_DEFAULT,
			};

			return retval;
		}, [accessName, personalRole]);

		const isFirstGapFixed = useMemo(
			() =>
				Children.toArray(children).some(
					(child) => (child as any)?.props?.fixed ?? false,
				),
			[children],
		);

		return (
			<Root
				ref={ref}
				onRowDoubleClick={
					AccessBtnGroup.edit ? onRowDoubleClick : undefined
				}
				{...props}
			>
				<Root.Column width={8} fixed={isFirstGapFixed}>
					<Root.HeaderCell hint={false}>
						<></>
					</Root.HeaderCell>
					<Root.Cell />
				</Root.Column>

				{children}

				<Root.Column width={8}>
					<Root.HeaderCell hint={false}>
						<></>
					</Root.HeaderCell>
					<Root.Cell />
				</Root.Column>
			</Root>
		);
	},
);

const LightTableMemo = memo(LightTableBase);

const LightTable = extend(LightTableMemo, {
	Context: Root.Context,
	Column: Root.Column,
	ColumnGroup: Root.ColumnGroup,
	Cell: Root.Cell,
	HeaderCell: Root.HeaderCell,
});

declare namespace LightTable {
	type Ref = Root.Ref;
	type Props = Root.Props & {
		accessName?: AccessKey[];
	};

	namespace Context {
		type Value = Root.Context.Value;
	}

	namespace Column {
		type Props = Root.Column.Props;
	}

	namespace ColumnGroup {
		type Props = Root.ColumnGroup.Props;
	}

	namespace Cell {
		type Props = Root.Cell.Props;
	}

	namespace HeaderCell {
		type Props = Root.Props;
	}
}

export default LightTable;
