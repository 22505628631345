import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../access";
import { useTypedSelector } from "../../../../../redux/store";

import Button from "./components/Button";
import Root from "./components/Root";

const ControlsBase: React.FC<Controls.Props> = ({
	onAdd,
	onInsert,
	onEdit,
	onDelete,
	onMoveUp,
	onMoveDown,
}) => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const AccessBtnGroup = useMemo(() => {
		const ACCESS_SECTION = [
			AccessKey.ORDERS,
			AccessKey.WIDGET_ORDER_ORDER_DETAILS,
			AccessKey.POINT_CONTROL_PANEL,
		];

		const retval = {
			addPoint: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.ADD_POINT),
				personalRole,
			),
			insertPoint: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.INSERT_POINT),
				personalRole,
			),
			editPoint: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.EDIT_POINT),
				personalRole,
			),
			deletePoint: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.DELETE_POINT),
				personalRole,
			),
			movePointUp: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.MOVE_POINT_UP),
				personalRole,
			),
			movePointDown: hasAccess(
				generateAccessName(
					...ACCESS_SECTION,
					AccessKey.MOVE_POINT_DOWN,
				),
				personalRole,
			),
		};

		return retval;
	}, [personalRole]);

	return (
		<Root gaps="3px* 1px" sizes="auto!*">
			{AccessBtnGroup.addPoint && (
				<Button
					variant="primary"
					tabIndex={-1}
					title={
						t("orderPageWidgets.orderDetails.controls.str0") ?? ""
					}
					settingsKeyId="addPoint"
					onClick={onAdd}
				>
					<Icon id="plus" size={16} />
				</Button>
			)}
			{AccessBtnGroup.insertPoint && (
				<Button
					variant="secondary"
					tabIndex={-1}
					title={
						t("orderPageWidgets.orderDetails.controls.str1") ?? ""
					}
					settingsKeyId="insertPoint"
					onClick={onInsert}
				>
					<Icon id="clipboard" size={20} />
				</Button>
			)}
			{AccessBtnGroup.editPoint && (
				<Button
					variant="secondary"
					tabIndex={-1}
					title={
						t("orderPageWidgets.orderDetails.controls.str2") ?? ""
					}
					settingsKeyId="editPoint"
					onClick={onEdit}
				>
					<Icon id="pencil" size={20} />
				</Button>
			)}
			{AccessBtnGroup.deletePoint && (
				<Button
					variant="secondary"
					tabIndex={-1}
					title={
						t("orderPageWidgets.orderDetails.controls.str3") ?? ""
					}
					settingsKeyId="deletePoint"
					onClick={onDelete}
				>
					<Icon id="trash" size={20} />
				</Button>
			)}
			{AccessBtnGroup.movePointUp && (
				<Button
					style={{
						height: 16,
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					}}
					variant="secondary"
					tabIndex={-1}
					title={
						t("orderPageWidgets.orderDetails.controls.str4") ?? ""
					}
					settingsKeyId="movePointUp"
					onClick={onMoveUp}
				>
					<Icon id="arrow-up" size={16} />
				</Button>
			)}
			{AccessBtnGroup.movePointDown && (
				<Button
					style={{
						height: 16,
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
					}}
					variant="secondary"
					tabIndex={-1}
					title={
						t("orderPageWidgets.orderDetails.controls.str5") ?? ""
					}
					settingsKeyId="movePointDown"
					onClick={onMoveDown}
				>
					<Icon id="arrow-down" size={16} />
				</Button>
			)}
		</Root>
	);
};

const Controls = memo(ControlsBase);

declare namespace Controls {
	interface Props {
		onAdd: () => void;
		onInsert: () => void;
		onEdit: () => void;
		onDelete: () => void;
		onMoveUp: () => void;
		onMoveDown: () => void;
	}
}

export default Controls;
