import React, { PropsWithChildren } from "react";

import {
	StyledGridItem,
	StyledRow,
	StyledSpan,
} from "../../../../../../common";

import Label from "./components/Label";

const Field: React.FC<Field.Props> = ({ area, label, children }) => (
	<StyledGridItem area={area}>
		<StyledRow gap="8px" alignItems="start">
			<Label>{label}</Label>
			<StyledSpan
				font={{ line: "14px" }}
				text={{ ls: "0em", ws: "pre-wrap" }}
				colors="rgb(100, 112, 121)"
			>
				{children}
			</StyledSpan>
		</StyledRow>
	</StyledGridItem>
);

declare namespace Field {
	interface Props extends PropsWithChildren {
		area: string;

		label: string;
	}
}

export default Field;
