import React, {
	Dispatch,
	ReactNode,
	useCallback,
	useLayoutEffect,
	useMemo,
} from "react";
import { CheckBox, Column } from "uikit";
import { RowDataType, SortType } from "rsuite-table";
import { useTranslation } from "react-i18next";

import Client from "../../../../../../../../../../services/Client";
import {
	useColumns,
	useSort,
	useVisibleColumns,
} from "../../../../../../../../../../hooks/useTableSettings";
import LightTable, {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../components/TableSettings";
import { ColumnId } from "../../../../../../../../../../constants/tables/types";
import tables from "../../../../../../../../../../constants/tables";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

import { columnProps } from "./constants";

const ModelTable: React.FC<ModelTable.Props> = ({
	loading,
	data,

	selected,
	setSelected,

	onEdit,
	onChangeTable,
	editorTable,
}) => {
	const { t } = useTranslation();

	const {
		tableRef,
		ctrlPressed,
		onKeyDown,
		onKeyUp,
		onScroll,
		setDataLength,
		widthScroll,
		heightScroll,
	} = useTableOptions({
		value: editorTable,
		setValue: onChangeTable,
	});

	useLayoutEffect(() => {
		setDataLength(data.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.length]);

	const scrollPos = useMemo(
		() => ({ x: widthScroll, y: heightScroll }),
		[heightScroll, widthScroll],
	);

	const columns = useColumns("client");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"customers.clients",
		"client",
	);
	const { sortMethod, setSortMethod } = useSort(
		"customers.clients",
		"client",
	);

	const tableRowClassName = useCallback(
		(rowData) => {
			if (selected.includes(rowData?.id ?? "")) return "selected";

			if (!(rowData as ModelTable.RowData)?._isActive)
				return "not-active";

			return "";
		},
		[selected],
	);

	const updateSelected = useCallback(
		(item: RowDataType<Client.Model>, force = false) => {
			const model = item as Client.Model;
			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					setSelected(selected.filter((id) => model.id !== id));
				} else {
					setSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? setSelected([])
					: setSelected([model.id]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const changeAllSelectedHandler = useCallback(
		(isAllSelected: boolean) => {
			if (isAllSelected) {
				setSelected(data.map(({ id }) => Number(id)));
			} else {
				setSelected([]);
			}
		},
		[setSelected, data],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<div tabIndex={-1} onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
				<LightTable
					shouldUpdateScroll={(name) =>
						name === "widthChanged" ? scrollPos : {}
					}
					ref={tableRef}
					fillHeight
					virtualized
					headerHeight={46}
					rowHeight={46}
					loading={loading}
					data={data}
					rowClassName={tableRowClassName}
					onRowClick={updateSelected}
					onRowDoubleClick={onEdit}
					accessName={ARR_ACCESS_PART_KEY[TabKeys.CLIENTS]}
					onScroll={onScroll}
					sortColumn={sortMethod?.column}
					sortType={sortMethod?.type}
					onSortColumn={(column, type) => {
						setSortMethod({ type, column });
					}}
				>
					<LightTable.Column width={36}>
						<LightTable.HeaderCell verticalAlign="middle">
							<CheckBox
								value={
									selected.length === data.length &&
									data.length !== 0
								}
								onChange={changeAllSelectedHandler}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell verticalAlign="middle">
							{(rowData) => (
								<CheckBox
									value={selected.includes(rowData.id)}
									onChange={() =>
										updateSelected(rowData, true)
									}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{columnIds.map((columnId) => (
						<LightTable.Column
							resizable
							key={columnId}
							{...columnProps[columnId]}
						>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									`tableColumns.customers.clients.${columnId}`,
								)}
							</LightTable.HeaderCell>

							<LightTable.Cell
								verticalAlign="middle"
								fullText
								dataKey={columnId}
							>
								{(rowData) =>
									(rowData as ModelTable.RowData)[columnId]
								}
							</LightTable.Cell>
						</LightTable.Column>
					))}
				</LightTable>
			</div>
			<TableSettings
				value={columnIds}
				defaultValue={tables.client.defaultIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	type ColumnValue = number | string | ReactNode;
	interface RowData extends Record<ColumnId<"client">, ColumnValue> {
		_isActive: boolean;
	}

	type TableData = RowData[];

	interface Props {
		loading: boolean;
		data: TableData;

		selected: number[];
		setSelected: Dispatch<number[]>;

		onEdit: Dispatch<number>;
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}
}

export default ModelTable;
