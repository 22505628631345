import makeTable from "./makeTable";

const cashlessAccountsSummaryReport = makeTable({
	allIds: [
		"account",
		"counterparty",
		"replenishmentUah",
		"orderCount",
		"orderAmountUah",
		"periodBalanceUah",
	],
	i18tPath: (id) => `tableColumns.accounts.roles.${id}`,
	defaultIds: [
		"account",
		"counterparty",
		"replenishmentUah",
		"orderCount",
		"orderAmountUah",
		"periodBalanceUah",
	],
	initialSortMethod: {
		column: "account",
		type: "asc",
	},
	initialWidths: {
		account: 150,
		counterparty: 150,
		replenishmentUah: 200,
		orderCount: 200,
		orderAmountUah: 200,
		periodBalanceUah: 200,
	},
	initialResizable: {
		account: true,
		counterparty: true,
		replenishmentUah: true,
		orderCount: true,
		orderAmountUah: true,
		periodBalanceUah: true,
	},
	initialSortable: {
		account: true,
		counterparty: true,
		replenishmentUah: true,
		orderCount: true,
		orderAmountUah: true,
		periodBalanceUah: true,
	},
});

export default cashlessAccountsSummaryReport;
