import React, { memo, useCallback } from "react";
import { Controller } from "react-hook-form";
import { Stepper, Icon } from "uikit";

import { UseCreateFormReturn } from "../../../../../../../../hooks/useCreateForm";
import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import { StyledRow } from "../../../../../../../../components/common";
import { Tab } from "../../types/Tab";
import { StyledPassengersIcon } from "../styled";

const CountPassengers: React.FC<CountPassengers.Props> = ({
	disabled,
	form,
}) => {
	const handleSave = useCallback(() => {
		form.setValue("orderSave", true);
	}, [form]);

	const onChange = useCallback(
		(value: Stepper.Value) => {
			if (value) {
				form.setValue("passengersCount", value);
				handleSave();
			}
		},
		[form, handleSave],
	);

	return (
		<StyledRow alignItems="center" h="28px">
			<Controller
				name="passengersCount"
				control={form.control}
				render={({ field }) => (
					<StyledRow flex={{ wrap: "nowrap" }} alignItems="center">
						<StyledPassengersIcon>
							<Icon id={"passengers"} size={16} />
						</StyledPassengersIcon>
						<Stepper
							disabled={disabled}
							value={field.value}
							min={1}
							onChange={onChange}
						/>
					</StyledRow>
				)}
			/>
		</StyledRow>
	);
};

declare namespace CountPassengers {
	interface Props {
		form: UseCreateFormReturn<Schema>;
		tab: Tab;
		disabled: boolean;
	}
}

export const CountPassengersMemo = memo(CountPassengers);
export default CountPassengers;
