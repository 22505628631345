/* eslint-disable no-case-declarations */
/* eslint-disable prettier/prettier */
import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { CheckBox, Row, ToggleButton } from "uikit";
import { useTranslation } from "react-i18next";

import Language from "../../../../../../../services/Language";
import PaymentSystem from "../../../../../../../services/PaymentSystem";
import TableSettings from "../../../../../../../components/TableSettings";
import LightTable from "../../../../../../../components/LightTable";
import translationPath from "../../../constants/translationPath";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../../constants/access";

import { Columns, columns, defaultColumnsId } from "./constants";
import Root from "./components/Root";
import DefaultMark from "./components/DefaultMark";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	lang,
	setSelected,
	onEdit,
	saveHandler,
}) => {
	const { t } = useTranslation();

	// eslint-disable-next-line no-param-reassign
	columns.forEach(
		(col) => (col.label = t(`${translationPath}.headerCells.${col.id}`)),
	);
	const [columnsId, setColumnsId] = useState(defaultColumnsId);

	const updateSelected = useCallback(
		(template) => {
			if (selected.includes(template.id)) {
				setSelected(selected.filter((id) => template.id !== id));
			} else {
				setSelected([...selected, template.id]);
			}
		},
		[setSelected, selected],
	);

	const renderColumns = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						width={column.width}
						flexGrow={column.width ? 0 : 1}
					>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							{t(`${translationPath}.headerCells.${column.id}`)}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(template) => {
								switch (columnId) {
									case Columns.ALLOWED:
										return (
											<div
												onClick={(event) =>
													event.stopPropagation()
												}
												style={{
													maxWidth: "max-content",
												}}
											>
												<ToggleButton.ToggleButton
													value={template?.active}
													onChange={(newActive) => {
														saveHandler(
															{
																...(template as PaymentSystem.Modified),
																active: newActive,
															},
															true,
														);
													}}
												/>
											</div>
										);

									case Columns.NAME:
										return (
											<Row gaps="5px">
												<span>{template.name}</span>
												{template.isDefault && (
													<DefaultMark>
														(default)
													</DefaultMark>
												)}
											</Row>
										);

									case Columns.PROVIDER:
										return template.paymentProvider;

									case Columns.COMPANIES:
										const companies: string[] =
											template.paymentAccounts.map(
												({ taxiService }) =>
													taxiService.company?.name?.[
														lang
													],
											);

										const uniqueCompanies: string[] =
											Array.from(new Set(companies));

										return (
											<Row align="center" gaps="8px*">
												{uniqueCompanies.map(
													(company, index) => (
														<Row
															align="center"
															key={index}
														>
															{company}
															{index !==
																uniqueCompanies.length -
																	1 && ","}
														</Row>
													),
												)}
											</Row>
										);

									case Columns.BRANCHES:
										return (
											<Row align="center" gaps="8px*">
												{template.paymentAccounts?.map(
													(
														{ taxiService },
														index,
													) => {
														const id =
															taxiService?.id;
														const name =
															taxiService
																?.settlement?.[
																lang
															];

														return (
															<Row
																align="center"
																key={id}
															>
																{name}
																{index !==
																	template
																		.paymentAccounts
																		.length -
																		1 &&
																	","}
															</Row>
														);
													},
												)}
											</Row>
										);

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnsId, lang, saveHandler, t],
	);

	return (
		<Root sizes="1fr auto!">
			<div>
				<LightTable
					data={value}
					virtualized
					fillHeight
					shouldUpdateScroll={false}
					rowClassName={(rowData) =>
						rowData && selected.includes(rowData.id)
							? "selected"
							: ""
					}
					onRowClick={updateSelected}
					onRowDoubleClick={onEdit}
					accessName={ARR_ACCESS_PART_KEY[TabKeys.EXECUTOR]}
				>
					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<CheckBox
								value={
									selected.length === value.length &&
									value.length !== 0
								}
								onChange={(isAllSelected) => {
									if (isAllSelected) {
										setSelected(value.map(({ id }) => id));
									} else {
										setSelected([]);
									}
								}}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(template) => (
								<CheckBox
									value={selected.includes(template.id)}
									onChange={() => {
										updateSelected(template);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{renderColumns}
					<LightTable.Column width={0}>
						<LightTable.HeaderCell>
							<></>
						</LightTable.HeaderCell>
						<LightTable.Cell />
					</LightTable.Column>
				</LightTable>
			</div>
			<TableSettings
				value={columnsId}
				defaultValue={defaultColumnsId}
				columns={columns}
				onChange={setColumnsId}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		value: PaymentSystem.Model[] | [];

		selected: number[];
		setSelected: Dispatch<number[]>;
		lang: Language;
		onEdit?: Dispatch<PaymentSystem.Modified>;
		saveHandler: (
			template: PaymentSystem.Modified,
			force?: boolean,
		) => Promise<void>;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
