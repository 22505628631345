import {
	Dispatch,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";
import { LatLngLiteral } from "leaflet";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import { Language } from "../../../../../../../services";
import { useTypedSelector } from "../../../../../../../redux/store";
import { IOrderPoint } from "../../../../../../../redux/constants/OrdersPage/order";

export interface UseAddPointRow {
	addTableRows: (row: IOrderPoint[]) => IOrderPoint[];
	tableRow: (id: number) => IOrderPoint;
	setBaseRow: Dispatch<SetStateAction<number>>;
}
export interface UseAddPointRowProps {
	companyID?: number;
	taxiServiceId?: number;
	coordinates: LatLngLiteral | number[];
	settlement?: string | Record<Language, string>;
}

/**
 *  `useAddPointRow`
 */
export const useAddPointRow = ({
	taxiServiceId,
	companyID,
	settlement,
	coordinates,
}: UseAddPointRowProps): UseAddPointRow => {
	const { t } = useTranslation();

	const { user } = useTypedSelector((state) => state.account);
	const companiesRedux = useTypedSelector(
		(state) => state.ordersPageReducer.companies,
	);

	const [baseRow, setBaseRow] = useState<number>(2);

	const language = useMemo(() => user?.settings?.language || "en", [user]);
	const companies = useMemo(() => companiesRedux, [companiesRedux]);

	const taxiServiceToOption = useCallback(
		(service) => ({
			coordinates: service?.settlementPoint,
			settlement: service.settlement?.[language],
			id: service.id,
		}),
		[language],
	);

	const taxiServiceOptions = useMemo(() => {
		const selectedCompany = companies.items?.find(
			(item) => item.id === companyID,
		);

		return selectedCompany.taxiServices.map(taxiServiceToOption);
	}, [companyID, companies, taxiServiceToOption]);

	const tableRow = useCallback<UseAddPointRow["tableRow"]>(
		(id) => {
			const exist = taxiServiceOptions.find((taxi) => {
				if (taxiServiceId === taxi.id) return taxi;
				return false;
			});

			return {
				id,
				coordinates: exist?.coordinates || coordinates,
				country: "",
				countryCode: "",
				district: "",
				entrance: "",
				flat: "",
				house: "",
				name: "",
				region: "",
				sector: undefined,
				settlement: exist?.settlement || settlement,
				settlementType: "",
				street: "",
				streetType: "",
				title: "",
				type: "",
			};
		},
		[coordinates, settlement, taxiServiceId, taxiServiceOptions],
	);

	const pointOnMap = useMemo(() => t(["point_on_map"]), [t]);

	const addTableRows = useCallback<UseAddPointRow["addTableRows"]>(
		(tableData = []) => {
			const normalacyArr = [...tableData].filter((item) => {
				if (!item) return false;
				return true;
			});
			const lengthItems = normalacyArr.length;
			if (lengthItems < baseRow) {
				const items = baseRow - lengthItems;
				const payload = new Array(items)
					.fill({})
					.map((_, i) => tableRow(i + 1 + lengthItems));
				return [...normalacyArr, ...payload].map((item) => {
					const newData = cloneDeep(item);
					if (!newData?.settlement) newData.settlement = pointOnMap;
					if (
						newData?.type &&
						!item?.street &&
						!newData?.settlementType
					) {
						newData.street =
							!newData.title || !newData.name ? pointOnMap : "";
					}
					return newData;
				});
			}
			return [...normalacyArr].map((item) => {
				const newData = cloneDeep(item);
				if (!newData?.settlement) newData.settlement = pointOnMap;
				if (newData?.type && !item?.street) {
					newData.street =
						!newData.title || !newData.name ? pointOnMap : "";
				}
				return newData;
			});
		},
		[baseRow, tableRow, pointOnMap],
	);

	return useMemo<UseAddPointRow>(
		() => ({
			addTableRows,
			setBaseRow,
			tableRow,
		}),
		[addTableRows, tableRow],
	);
};
