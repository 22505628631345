/* eslint-disable no-shadow */

import React, { Dispatch, useCallback, useMemo, useRef } from "react";
import { Column } from "uikit";
import { SortType } from "rsuite-table";
import { clone, isString } from "lodash";

import { Dispatcher, KeyBinder } from "../../../../../../../../../../services";
import { getColumnsModeTwo } from "../../../../../../../../../../utils";
import Map from "../../../../../../../../../../redux/services/Map";
import {
	useColumns,
	useVisibleColumns,
	useWidths,
} from "../../../../../../../../../../hooks/useTableSettings";
import LightTable, {
	useTableOptions,
} from "../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../components/TableSettings";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

import columnBase, { ColumnId as LocalColumnId } from "./columns";

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,
	sort,
	loading,
	data,
	language,
	onChangeSelected,
	onChangeSort,
	onEdit,
	onLoadMore,
}) => {
	const defaultColumnIds: Parameters<typeof getColumnsModeTwo>[1] = useMemo(
		() => [
			"fullName",
			"phones",
			"alias",
			"role",
			"status",
			"registeredAt",
			"access",
		],
		[],
	);

	const {
		// sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,
		lang,
		widthScroll,
	} = useTableOptions({
		heightRow: 32,
		itemLimit: 2,
	});

	const columns = useColumns("dispatcher");

	const { columnIds, setColumnIds } = useVisibleColumns(
		"accounts.dispatcher",
		"dispatcher",
	);
	const { widths, setWidths } = useWidths(
		"accounts.dispatcher",
		"dispatcher",
	);

	const canLoadMoreRef = useRef(true);

	const modelTableColumns = useMemo<ModelTable.Column[]>(
		() =>
			columns
				.map((column) => ({
					id: column.id as LocalColumnId,
					width: widths[column.id],
					visible: columnIds.includes(column.id as LocalColumnId),
				}))
				.sort((leftColumn, rightColumn) => {
					const leftIndex = columnIds.indexOf(leftColumn.id);
					const rightIndex = columnIds.indexOf(rightColumn.id);
					return leftIndex - rightIndex;
				}),
		[columns, widths, columnIds],
	);

	const modelTableOnChangeColumns = useCallback(
		(columns: ModelTable.Column[]) => {
			setColumnIds(
				columns
					.filter((column) => column.visible)
					.map((column) => column.id),
			);
			setWidths(
				columns.reduce((accumulator, column) => {
					accumulator[column.id] = column.width;
					return accumulator;
				}, {} as Record<LocalColumnId, number>),
			);
		},
		[setColumnIds, setWidths],
	);

	const renderedColumns = useMemo(
		() =>
			modelTableColumns
				.filter((column) => column.visible)
				.map((column) => {
					const node = columnBase[column.id]?.render({
						width: column.width,
						language: lang,

						onResize: (width, columnId) => {
							const newColumns = modelTableColumns.map(
								(columns) =>
									columns.id === columnId
										? { ...columns, width: width ?? 0 }
										: columns,
							);

							modelTableOnChangeColumns(newColumns);
						},
					});

					return node;
				}),
		[modelTableColumns, lang, modelTableOnChangeColumns],
	);

	const tableRowClassName = useCallback(
		(rowData) => {
			if (selected.includes(rowData?.id ?? "")) return "selected";

			if (!rowData?.active) return "not-active";

			return "";
		},
		[selected],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore, tableRef]);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				onChangeSelected(newSelected);
			} else
				onChangeSelected(
					selected.filter((selectedId) => selectedId !== id),
				);
		},
		[onChangeSelected, selected],
	);

	const tableOnRowClick = useCallback(
		(rowData) =>
			KeyBinder.isControlPressed
				? setItemSelection(rowData.id, !selected.includes(rowData.id))
				: onChangeSelected(
						selected.includes(rowData.id) ? [] : [rowData.id],
				  ),
		[onChangeSelected, selected, setItemSelection],
	);

	const rowHeight = useCallback((rowData) => {
		const taxiServiceDescription = rowData?.description;
		const defaultHeight = 44;

		if (taxiServiceDescription && isString(taxiServiceDescription)) {
			const fontSize = 13;
			const fontBetween = 3;
			const lineHeight = fontSize + fontBetween;
			const padding = 28;

			const lines = taxiServiceDescription.split("\n").length;

			const calculatedHeight = lines * lineHeight;

			return Math.max(defaultHeight, calculatedHeight + padding);
		}

		return defaultHeight;
	}, []);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<LightTable
				ref={tableRef}
				fillHeight
				virtualized
				shouldUpdateScroll={false}
				headerHeight={44}
				rowHeight={rowHeight}
				sortColumn={sort.column}
				sortType={sort.type}
				loading={loading}
				data={data}
				rowClassName={tableRowClassName}
				onScroll={tableOnScroll}
				onRowClick={tableOnRowClick}
				onRowDoubleClick={onEdit}
				accessName={ARR_ACCESS_PART_KEY[TabKeys.USERS]}
				onSortColumn={(column, type) => onChangeSort({ column, type })}
			>
				{renderedColumns}
			</LightTable>

			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Column {
		id: LocalColumnId;
		width: number;
		visible: boolean;
	}

	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		selected: number[];
		sort: Sort;

		loading: boolean;
		data: Dispatcher.Model[];
		language: Map.Language;

		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;

		onEdit: Dispatch<number>;
		onLoadMore: () => void;
	}
}

export default ModelTable;

// /* eslint-disable no-shadow */

// import React, {
// 	Dispatch,
// 	ReactElement,
// 	useCallback,
// 	useMemo,
// 	useRef,
// } from "react";
// import { UseTranslationResponse, useTranslation } from "react-i18next";
// import { CheckBox, Column } from "uikit";
// import { SortType } from "rsuite-table";
// import { clone, isUndefined } from "lodash";
// import moment from "moment";

// import KeyBinder from "../../../../../../../../../../services/KeyBinder";
// import TaxiService from "../../../../../../../../../../services/TaxiService";
// import Language from "../../../../../../../../../../services/Language";
// import { getColumnsModeTwo } from "../../../../../../../../../../utils";
// import Map from "../../../../../../../../../../redux/services/Map";
// import { useTypedSelector } from "../../../../../../../../../../redux/store";
// import {
// 	useVisibleColumns,
// 	useWidths,
// } from "../../../../../../../../../../hooks/useTableSettings";
// import mapByKey from "../../../../../../../../../../utils/mapByKey";
// import LightTable from "../../../../../../../../../../components/LightTable";
// import TableSettings from "../../../../../../../../../../components/TableSettings";

// interface ColumnContext {
// 	language: Language;
// 	taxiServiceById: Record<number, TaxiService.Model>;
// 	width: number;
// 	t: UseTranslationResponse<"translation", undefined>[0];
// 	onResize: (width?: number, columnId?: string) => void;
// }

// const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
// 	fullName: ({ width, onResize, t }) => (
// 		<LightTable.Column width={width} sortable resizable onResize={onResize}>
// 			<LightTable.HeaderCell>
// 				{t(
// 					"pages.mainPage.pages.accounts.tabs.users.content.modelTable.str100",
// 				) ?? ""}
// 			</LightTable.HeaderCell>
// 			<LightTable.Cell dataKey="fullName">
// 				{(rowData) =>
// 					`${rowData.person.lastName} ${rowData.person.firstName} ${rowData.person.fatherName}`.trim()
// 				}
// 			</LightTable.Cell>
// 		</LightTable.Column>
// 	),

// 	alias: ({ width, onResize, t }) => (
// 		<LightTable.Column width={width} sortable resizable onResize={onResize}>
// 			<LightTable.HeaderCell>
// 				{t(
// 					"pages.mainPage.pages.accounts.tabs.users.content.modelTable.str101",
// 				) ?? ""}
// 			</LightTable.HeaderCell>
// 			<LightTable.Cell dataKey="alias">
// 				{(rowData) => rowData.alias}
// 			</LightTable.Cell>
// 		</LightTable.Column>
// 	),

// 	phones: ({ width, onResize, t }) => (
// 		<LightTable.Column width={width} sortable resizable onResize={onResize}>
// 			<LightTable.HeaderCell>
// 				{t(
// 					"pages.mainPage.pages.accounts.tabs.users.content.modelTable.str102",
// 				) ?? ""}
// 			</LightTable.HeaderCell>
// 			<LightTable.Cell dataKey="phones">
// 				{(rowData) =>
// 					rowData.additionalFields?.phones?.join(", ") ?? ""
// 				}
// 			</LightTable.Cell>
// 		</LightTable.Column>
// 	),

// 	role: ({ width, onResize, t }) => (
// 		<LightTable.Column width={width} sortable resizable onResize={onResize}>
// 			<LightTable.HeaderCell>
// 				{t(
// 					"pages.mainPage.pages.accounts.tabs.users.content.modelTable.str103",
// 				) ?? ""}
// 			</LightTable.HeaderCell>
// 			<LightTable.Cell dataKey="role">
// 				{(rowData) =>
// 					rowData.roles?.map((role) => role.name || "").join(", ") ??
// 					""
// 				}
// 			</LightTable.Cell>
// 		</LightTable.Column>
// 	),

// 	status: ({ width, onResize, t }) => {
// 		const statusToName: Record<string, string> = {
// 			active:
// 				t(
// 					`pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.status.active`,
// 				) ?? "",
// 			blocked:
// 				t(
// 					`pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.status.blocked`,
// 				) ?? "",
// 			dismissed:
// 				t(
// 					`pages.mainPage.pages.accounts.tabs.users.editModal.content.mainTab.workData.status.dismissed`,
// 				) ?? "",
// 		};

// 		return (
// 			<LightTable.Column
// 				width={width}
// 				sortable
// 				resizable
// 				onResize={onResize}
// 			>
// 				<LightTable.HeaderCell>
// 					{t(
// 						"pages.mainPage.pages.accounts.tabs.users.content.modelTable.str104",
// 					) ?? ""}
// 				</LightTable.HeaderCell>
// 				<LightTable.Cell dataKey="status">
// 					{(rowData) => statusToName[rowData.status]}
// 				</LightTable.Cell>
// 			</LightTable.Column>
// 		);
// 	},

// 	registeredAt: ({ width, onResize, t }) => (
// 		<LightTable.Column width={width} sortable resizable onResize={onResize}>
// 			<LightTable.HeaderCell>
// 				{t(
// 					"pages.mainPage.pages.accounts.tabs.users.content.modelTable.str105",
// 				) ?? ""}
// 			</LightTable.HeaderCell>
// 			<LightTable.Cell dataKey="registeredAt">
// 				{(rowData) => moment(rowData.createdAt).format("DD.MM.YYYY")}
// 			</LightTable.Cell>
// 		</LightTable.Column>
// 	),

// 	access: ({ taxiServiceById, language, width, onResize, t }) => (
// 		<LightTable.Column width={width} sortable resizable onResize={onResize}>
// 			<LightTable.HeaderCell>
// 				{t(
// 					"pages.mainPage.pages.accounts.tabs.users.content.modelTable.str106",
// 				) ?? ""}
// 			</LightTable.HeaderCell>
// 			<LightTable.Cell dataKey="taxiService">
// 				{(rowData) =>
// 					(rowData.taxiServiceIds as number[])
// 						.map(
// 							(taxiServiceId) =>
// 								taxiServiceById[taxiServiceId]?.settlement[
// 									language
// 								] ?? "",
// 						)
// 						.filter((taxiServiceName) => taxiServiceName)
// 						.join(", ")
// 				}
// 			</LightTable.Cell>
// 		</LightTable.Column>
// 	),

// 	birthAt: ({ width, onResize, t }) => (
// 		<LightTable.Column width={width} sortable resizable onResize={onResize}>
// 			<LightTable.HeaderCell>
// 				{t(
// 					"pages.mainPage.pages.accounts.tabs.users.content.modelTable.str107",
// 				) ?? ""}
// 			</LightTable.HeaderCell>
// 			<LightTable.Cell dataKey="birthAt">
// 				{(rowData) => moment(rowData.birthAt).format("DD.MM.YYYY")}
// 			</LightTable.Cell>
// 		</LightTable.Column>
// 	),
// };

// const ModelTable: React.FC<ModelTable.Props> = ({
// 	selected,
// 	sort,
// 	loading,
// 	data,
// 	language,
// 	onChangeSelected,
// 	onChangeSort,
// 	onEdit,
// 	onLoadMore,
// }) => {
// 	const { t } = useTranslation();

// 	const defaultColumnIds: Parameters<typeof getColumnsModeTwo>[1] = useMemo(
// 		() => [
// 			"fullName",
// 			"phones",
// 			"alias",
// 			"role",
// 			"status",
// 			"registeredAt",
// 			"access",
// 		],
// 		[],
// 	);

// 	const columns = useMemo(
// 		() => getColumnsModeTwo(t, defaultColumnIds),
// 		[t, defaultColumnIds],
// 	);

// 	const { columnIds, setColumnIds } = useVisibleColumns(
// 		"accounts.dispatchers",
// 		"dispatcher",
// 	);
// 	const { widths, setWidths } = useWidths(
// 		"accounts.dispatchers",
// 		"dispatcher",
// 	);

// 	const { models: taxiServices } = useTypedSelector(
// 		(state) => state.taxiServices,
// 	);

// 	const taxiServiceById = useMemo(
// 		() => mapByKey(taxiServices, "id"),
// 		[taxiServices],
// 	);

// 	const canLoadMoreRef = useRef(true);
// 	const tableRef = useRef<LightTable.Ref | null>(null);

// 	const tableRowClassName = useCallback(
// 		(rowData) => {
// 			if (selected.includes(rowData?.id ?? "")) return "selected";

// 			if (rowData?.status !== "active") return "not-active";

// 			return "";
// 		},
// 		[selected],
// 	);

// 	const tableOnScroll = useMemo(() => {
// 		canLoadMoreRef.current = true;

// 		return (x: number, y: number) => {
// 			if (!canLoadMoreRef.current) return;

// 			const contextHeight = data.length * 44;
// 			const top = Math.abs(y);
// 			const tableContainerHeight =
// 				tableRef.current?.root.getBoundingClientRect().height ?? 0;

// 			if (contextHeight - top - tableContainerHeight < 300) {
// 				onLoadMore();

// 				canLoadMoreRef.current = false;
// 			}
// 		};
// 	}, [data.length, onLoadMore]);

// 	const setItemSelection = useCallback(
// 		(id: number, value: boolean) => {
// 			if (value) {
// 				const newSelected = clone(selected);

// 				newSelected.push(id);

// 				onChangeSelected(newSelected);
// 			} else
// 				onChangeSelected(
// 					selected.filter((selectedId) => selectedId !== id),
// 				);
// 		},
// 		[onChangeSelected, selected],
// 	);

// 	const tableOnRowClick = useCallback(
// 		(rowData) =>
// 			KeyBinder.isControlPressed
// 				? setItemSelection(rowData.id, !selected.includes(rowData.id))
// 				: onChangeSelected(
// 						selected.includes(rowData.id) ? [] : [rowData.id],
// 				  ),
// 		[onChangeSelected, selected, setItemSelection],
// 	);

// 	return (
// 		<Column sizes="1fr auto!" maxedWidth maxedHeight>
// 			<LightTable
// 				ref={tableRef}
// 				fillHeight
// 				virtualized
// 				shouldUpdateScroll={false}
// 				headerHeight={44}
// 				rowHeight={44}
// 				sortColumn={sort?.column}
// 				sortType={sort?.type}
// 				loading={loading}
// 				data={data}
// 				rowClassName={tableRowClassName}
// 				onScroll={tableOnScroll}
// 				onRowClick={tableOnRowClick}
// 				onRowDoubleClick={onEdit}
// 				onSortColumn={(column, type) => onChangeSort({ column, type })}
// 			>
// 				<LightTable.Column width={36}>
// 					<LightTable.HeaderCell verticalAlign="middle">
// 						<CheckBox
// 							value={
// 								selected.length === data.length &&
// 								data.length !== 0
// 							}
// 							onChange={(value) =>
// 								onChangeSelected(
// 									value ? data.map((item) => item.id) : [],
// 								)
// 							}
// 						/>
// 					</LightTable.HeaderCell>
// 					<LightTable.Cell verticalAlign="middle">
// 						{(rowData) => (
// 							<CheckBox
// 								value={selected.includes(rowData.id)}
// 								onChange={(selected) =>
// 									KeyBinder.isControlPressed
// 										? setItemSelection(rowData.id, selected)
// 										: onChangeSelected(
// 												selected ? [rowData.id] : [],
// 										  )
// 								}
// 							/>
// 						)}
// 					</LightTable.Cell>
// 				</LightTable.Column>

// 				{columnIds.map((columnId) =>
// 					Columns[columnId]({
// 						language,
// 						taxiServiceById,
// 						width: widths[columnId],
// 						onResize: (width, columnId) => {
// 							if (isUndefined(width) || isUndefined(columnId))
// 								return;

// 							setWidths({
// 								...widths,
// 								[columnId]: width,
// 							});
// 						},
// 						t,
// 					}),
// 				)}
// 			</LightTable>

// 			<TableSettings
// 				value={columnIds}
// 				defaultValue={defaultColumnIds}
// 				columns={columns}
// 				onChange={setColumnIds}
// 			/>
// 		</Column>
// 	);
// };

// declare namespace ModelTable {
// 	interface Sort {
// 		column?: string;
// 		type?: SortType;
// 	}

// 	interface Props {
// 		selected: number[];
// 		sort: Sort;

// 		loading: boolean;
// 		data: (unknown & { id: number })[];
// 		language: Map.Language;

// 		onChangeSelected: Dispatch<number[]>;
// 		onChangeSort: Dispatch<Sort>;

// 		onEdit: Dispatch<number>;
// 		onLoadMore: () => void;
// 	}
// }

// export default ModelTable;
