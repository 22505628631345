import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { LatLngLiteral } from "leaflet";

import { History as BaseHistory } from "../../services/Order/getHistory";
import OrderBase from "../../../services/Order";
import { Language } from "../../../assets/languages/langs";
import ArchiveFilter from "../../../types/ArchiveFilter";

import {
	ArchiveOrderHistory,
	ArchiveOrderHistoryUser,
	ArchiveOrdersPagination,
	ArchiveOrdersFilters,
} from "./Orders/interface";

export namespace Archive {
	export type Filter = ArchiveFilter;
	export interface Player {
		/** Stop or start to the player. */
		toggle: boolean;
		/** The start point of the way. */
		position: LatLngLiteral | null;
		/** The end point of the way. */
		next: LatLngLiteral | null;
		/** Start time of the way. */
		date: number;
		/** How many steps should be. */
		max: number;
		/** The default should be 1. */
		speed: number;
		/** the time between two points */
		duration: number;
	}

	export interface SegmentPlayer {
		date: number;
		point: LatLngLiteral;
		direction: LatLngLiteral;
		speed: number;
		accuracy: number;
		altitude: number;
		executor: { id: number; status: string };
		executorCallSign: string;
		executorId: number;
		taxiServiceId: number;
	}
	export interface LocationPlayer {
		segments: SegmentPlayer[][];
	}
	export interface ValueOrderSectors {
		point: LatLngLiteral;
	}

	export namespace Orders {
		export type Pagination = ArchiveOrdersPagination;
		export type Filters = ArchiveOrdersFilters;

		export interface Order
			extends Omit<
				OrderBase.Model,
				| "status"
				| "source"
				| "type"
				| "passengers"
				| "executingStage"
				| "paymentType"
			> {
			id: number;
			v: number;
			orderDate: string;
			createdAt: string;
			updatedAt: string;
			offer: any | null;
			closedAt: any | null;
			type: ValueOrderField;
			status: ValueOrderStatus;
			source: ValueOrderSource;
			sectors: ValueOrderSectors;
			orderNote: string;
			orderNumber: number;
			additionalFields: AdditionalFields;
			taxiService: Record<string, any>;
			route: Route;
			passengers: Passenger[];
			closedOrderComments: any[];
			orderToServices: any[];
			paymentType: ValueOrderPayment;
			executingStage: ValueOrderExecutingStage;
			executorToOrder: any[];
			executingStageUpdatedAt: string;
		}

		export enum CustomerBonusUsageType {
			BonusTrip = "bonusTrip",
			BonusBalance = "bonusBalance",
		}

		export interface CustomerBonusUsage {
			/**
			 * Indicates whether the customer uses bonuses or not.
			 */
			active: boolean;

			/**
			 * Type of the bonus usage.
			 */
			type: CustomerBonusUsageType;

			/**
			 * The limits of the bonus usage.
			 */
			limit: {
				active: boolean;
				maxAmount: number;
			};
		}

		export interface CustomerDiscountUsage {
			/**
			 * Indicates whether the customer uses discounts or not.
			 */
			active: boolean;
		}

		export interface DiscountCost {
			/**
			 * The fixed discount amount
			 */
			fixedAmount: number;

			/**
			 * The customer bonus amount. If the customer uses bonuses, then the bonus amount will be deducted from the order cost.
			 */
			bonusAmount: number;

			/**
			 * The total compensation amount to the executor in specified currency.
			 */
			compensation: number;
		}

		export interface Discount {
			active: boolean;
			bonusUsage: CustomerBonusUsage;
			discountUsage: CustomerDiscountUsage;
		}

		export interface PersonPhone {
			number: string;
			group?: number;
		}

		export interface PersonEmail {
			value: string;
			group?: number;
		}

		export interface Person {
			id: number;
			name: string;
			surname: string;
			fatherName: string;
			phones: PersonPhone[];
			emails: PersonEmail[];
		}
		export interface Customer {
			id: number;
			isVerified: boolean;
			createdAt: string | null;
			updatedAt: string | null;
			deletedAt: string | null;
			companyId: number;
			ordersCount?: number;
			person: Person;
		}
		export interface PointFeatureSector {
			id: number;
			name: Record<Language, string>;
			point: ValueOrderSectors["point"];
			pointIndex: number;
		}

		export interface PointFeature {
			type: string;
			flat: string;
			entrance: string;
			name: string;
			house: string;
			country: string;
			countryCode: string;
			sector: PointFeatureSector | null;
			street: string;
			streetType: string;
			settlement: string;
			settlementType: string;
			district: string;
			region: string;
			customStreet: string;
			customHouse: string;
		}

		export interface Point {
			id: number;
			position: number;
			visited: boolean;
			createdAt: string;
			updatedAt: string;
			feature: PointFeature;
			coordinates: LatLngLiteral;
		}

		export type NewPointModal = Pick<Point, "coordinates"> &
			Point["feature"];

		export interface Passenger {
			id: number;
			rideNumber: number;
			createdAt: string;
			updatedAt: string;
			closedAt: string | null;
			closedType: string;
			paymentType: any;
			location: LatLngLiteral;
			points: Point[];
			customer: Customer;
		}

		export interface AdditionalFields {
			customerNotes: string;
			executorNotes: string;
			additionalCost: number;
			passengersCount: number;
			hourlyMilliseconds: number;
			transportationType: ValueOrderTransportationType;
			suburbanDistanceOverride?: {
				value: number;
				shrink?: boolean;
			};
			rateSettings?: {
				includePickupCost?: boolean;
				isRoundTrip?: boolean;
			};
			idleTimeMilliseconds: number;
			waitingTimeMilliseconds: number;
			/**
			 * The usage of the customer bonuses.
			 *
			 * If the customer want to use bonuses, then the `bonusUsage` field should be set.
			 * Contains the bonus usage settings like limits, type, amount, etc.
			 */
			discount: Discount | null;
			discountCost?: DiscountCost;

			/** @deprecated not used  */
			compensation?: {
				active: boolean;
				type: "percent" | "uah";
				whom: "customer" | "taxiService";
				value: number;
			};
			/** @deprecated not used  */
			surcharge?: {
				active: boolean;
				type: "percent" | "uah";
				value: number;
			};

			/** @deprecated not used  */
			rideBonus?: {
				active: boolean;
				number: number;
				value: number;
			};
			/** @deprecated not used  */
			phoneTopUp?: {
				active: boolean;
				value: number;
			};
			taximeter: boolean;
			processedByTaximeter: boolean;
		}
		export interface Route {
			distance: number;
			points: LatLngLiteral[];
			segments: LatLngLiteral[][];
			sectors: Record<string, any>[];
			priseZone: Record<string, any>[];
		}

		export namespace Order {
			export type HistoryChange = BaseHistory.Change;
			export type HistoryField = BaseHistory.Field;
			export type History = ArchiveOrderHistory;
			export type HistoryUser = ArchiveOrderHistoryUser;
			export type CostStage = BaseHistory.OrderCostStage;
			export type CostCurrency = BaseHistory.OrderCostCurrency;
			export type Cost = BaseHistory.OrderCost;

			export enum RoundingMethod {
				GENERAL = "general",
				CEIL = "ceil",
				FLOOR = "floor",
			}
		}
	}
	// GPS
	export namespace GPS {}
	// CallS
	export namespace Calls {
		export enum Columns {
			TYPE = "type",
			CALL_RECEIVED_TIME = "callReceivedTime",
			EMPLOYEE = "employee",
			SURNAME = "surname",
			NAME = "name",
			FATHER_NAME = "fatherName",
			SIP = "sip",
			SERVICE_PHONE = "servicePhone",
			CUSTOMER_PHONE = "customerPhone",
			COMPANY = "company",
			BRANCH = "branch",
			STATUS = "status",
			WAITING_TIME = "waitingTime",
			CALL_DURATION = "callDuration",
			RECORD = "record",
		}

		export const ConstantOrderCalls = {
			TYPE: "type",
			CALL_RECEIVED_TIME: "callReceivedTime",
			EMPLOYEE: "employee",
			SURNAME: "surname",
			NAME: "name",
			FATHER_NAME: "fatherName",
			SIP: "sip",
			SERVICE_PHONE: "servicePhone",
			CUSTOMER_PHONE: "customerPhone",
			COMPANY: "company",
			BRANCH: "branch",
			STATUS: "status",
			WAITING_TIME: "waitingTime",
			CALL_DURATION: "callDuration",
			RECORD: "record",
		} as const;
		export type TypeOrderCalls = typeof ConstantOrderCalls;
		export type ValueOrderCalls =
			(typeof ConstantOrderCalls)[keyof typeof ConstantOrderCalls];
	}
	export namespace DriverShifts {}
	export namespace Report {}

	export const ConstantOrderTransportationType = {
		PASSENGER: "passenger",
		CARGO: "cargo",
	} as const;
	export type TypeOrderTransportationType =
		typeof ConstantOrderTransportationType;
	export type ValueOrderTransportationType =
		(typeof ConstantOrderTransportationType)[keyof typeof ConstantOrderTransportationType];

	export const ConstantOrderExecutingStage = {
		DISPATCHER: "dispatcher",
		ACCEPTED: "accepted",
		DELAYED: "delayed",
		ARRIVED: "arrived",
		WAITING_CLIENT: "waiting_client",
		WITH_CLIENTS: "with_clients",
	} as const;
	export type TypeOrderExecutingStage = typeof ConstantOrderExecutingStage;
	export type ValueOrderExecutingStage =
		(typeof ConstantOrderExecutingStage)[keyof typeof ConstantOrderExecutingStage];

	export const ConstantOrderSource = {
		PHONE: "phone",
		EXECUTOR: "executor",
		DISPATCHER: "dispatcher",
		APP: "app",
	} as const;
	export type TypeOrderSource = typeof ConstantOrderSource;
	export type ValueOrderSource =
		(typeof ConstantOrderSource)[keyof typeof ConstantOrderSource];

	export enum EnumOrderPaymentChangeType {
		COMPANY_BILL_TO_CASH = "company_bill_to_cash",
	}

	export const OrderPaymentChangeType = {
		COMPANY_BILL_TO_CASH: "company_bill_to_cash",
	} as const;
	export type TypeOrderPaymentChangeType = typeof OrderPaymentChangeType;
	export type ValueOrderPaymentChangeType =
		(typeof OrderPaymentChangeType)[keyof typeof OrderPaymentChangeType];

	export const ConstantOrderPayment = {
		CASH: "cash",
		CARD: "card",
		COMPANY_BILL: "company_bill",
		INVOICE: "invoice",
	} as const;
	export type TypeOrderPayment = typeof ConstantOrderPayment;
	export type ValueOrderPayment =
		(typeof ConstantOrderPayment)[keyof typeof ConstantOrderPayment];

	export const ConstantOrderAction = {
		UPDATE: "update",
		CREATE: "create",
		CLOSE: "close",
		REVERT: "revert",
		DELETE: "delete",
		REFUSE: "refuse",
	} as const;
	export type TypeOrderAction = typeof ConstantOrderAction;
	export type ValueOrderAction =
		(typeof ConstantOrderAction)[keyof typeof ConstantOrderAction];

	export const ConstantOrderType = {
		DEFAULT: "default",
		UPDATE: "update",
		ARRAY_ITEM_ADD: "array_item_add",
		ARRAY_ITEM_REMOVE: "array_item_remove",
		ARRAY_ITEM_UPDATE: "array_item_update",
	} as const;
	export type TypeOrderType = typeof ConstantOrderType;
	export type ValueOrderType =
		(typeof ConstantOrderType)[keyof typeof ConstantOrderType];

	export const ConstantOrderField = {
		TYPE: "type",
		ORDER_NOTE: "orderNote",
		STATUS: "status",
		SOURCE: "source",
		ORDER_REVERT: "orderNumber",
		ORDER_DELETE: "orderDate",
		TAXI_SERVICE: "taxiService",
		SERVICES: "services",
		POINTS: "points",
		PASSENGER_CUSTOMERS: "passenger.customers",
		PASSENGER_POINTS: "passenger.points",
		ORDER_CLOSED_ORDERS_COMMENTS: "closedOrdersComments",
		COST: "cost",
		IDLE_TIME_MILLISECONDS: "idleTimeMilliseconds",
		PASSENGER_COUNT: "passengersCount",
		WAITING_TIME_MILLISECONDS: "waitingTimeMilliseconds",
		TRANSPORTATION_TYPE: "transportationType",
		EXECUTOR_NOTES: "executorNotes",
		CUSTOMER_NOTES: "customerNotes",
		OUTSIDE_SETTLEMENT_KM: "outsideSettlementKm",
		ADDITIONAL_COST: "additionalCost",
		HOURLY_MILLISECONDS: "hourlyMilliseconds",
		RATE_SETTINGS: "rateSettings",
		BILLING_CUSTOMER: "billingCustomer",
		PAYMENT_ACCOUNT: "paymentAccount",
		EXECUTORS: "executors",
		CAR_CLASSES: "carClasses",
		CARS: "cars",
		PHONES: "phones",
	} as const;
	export type TypeOrderField = typeof ConstantOrderField;
	export type ValueOrderField =
		(typeof ConstantOrderField)[keyof typeof ConstantOrderField];

	export const ConstantOrderStatus = {
		CREATING: "creating",
		CREATED: "created",
		INSTANT_DISTRIBUTION: "instant_distribution",
		DISTRIBUTION: "distribution",
		REDISTRIBUTION: "redistribution",
		DISTRIBUTION_AND_FREE_WAVE: "distribution_and_free_wave",
		FREE_WAVE: "free_wave",
		ASSIGNED_MANUALLY: "assigned_manually",
		EXECUTING: "executing",
		CLOSED: "closed",
	} as const;
	export type TypeOrderStatus = typeof ConstantOrderStatus;
	export type ValueOrderStatus =
		(typeof ConstantOrderStatus)[keyof typeof ConstantOrderStatus];

	export const ConstantOrderCostCalculationStage = {
		MARGIN: "margin",
		BOARDING_COST: "boarding_cost",
		ADDITIONAL_COST: "additional_cost",
		MIN_AFFORDABLE_ROUTE_COST: "min_affordable_route_cost",
		SETTLEMENT_ROUTE_COST: "settlement_route_cost",
		SUBURBAN_ROUTE_COST: "suburban_route_cost",
		FIXED_RATE: "fixed_rate",
		RATE_GRID_SUBURBAN_COST: "rate_grid_suburban_cost",
		RATE_GRID_SETTLEMENT_COST: "rate_grid_settlement_cost",
		HOURLY_RATE_WAITING: "hourly_rate_waiting",
		HOURLY_RATE_IDLE: "hourly_rate_idle",
		HOURLY_RATE_RIDE: "hourly_rate_ride",
		PICKUP_NEAREST_COST: "pickup_nearest_cost",
		PICKUP_ZONE_COST: "pickup_zone_cost",
		PICKUP_ZONE_REVERSE_COST: "pickup_zone_reverse_cost",
		PICKUP_SETTLEMENT_COST: "pickup_settlement_cost",

		// GENERAL_MARGIN: "margin_general",
		// ORDER_SUM_MARGIN: "margin_surcharge_to_order_sum",
		// PRELIMINARY_MARGIN: "preliminary_margin",
		// APP_ORDER_MARGIN: "online_order_margin",
		// EXECUTOR_ORDER_MARGIN: "executor_order_margin",
		// WEBSITE_ORDER_MARGIN: "website_order_margin",
		// PICKUP_COST_MARGIN: "pickup_cost_margin",
		// PASSENGER_MARGIN: "passengers_margin",
		// SERVICE_MARGIN: "services_margin",
		// ADDITIONAL_POINT_MARGIN: "additional_point_margin",
		// CREDIT_CARD_PAYMENT_MARGIN: "credit_card_payment_margin",
		// INVOICE_PAYMENT_MARGIN: "invoice_payment_margin",
		// NON_CASH_PAYMENT_MARGIN: "non_cash_payment_margin",
	};
	export type TypeOrderCostCalculationStage =
		typeof ConstantOrderCostCalculationStage;
	export type ValueOrderCostCalculationStage =
		(typeof ConstantOrderCostCalculationStage)[keyof typeof ConstantOrderCostCalculationStage];

	export const ConstantOrderSlice = {
		ORDERS_ERRORS: "archive/orders/errors",
		ORDERS_TOGGLES: "archive/orders/toggles",
		ORDERS_LOADINGS: "archive/orders/loadings",
		ORDERS_FILTERS: "archive/orders/filters",
		ORDERS_ORDERS: "archive/orders/orders",
		ORDERS_HISTORY: "archive/orders/history",
		ORDERS_PLAYER: "archive/orders/player",
	};
	export type TypeOrderSlice = typeof ConstantOrderSlice;
	export type ValueOrderSlice =
		(typeof ConstantOrderSlice)[keyof typeof ConstantOrderSlice];
}

export const ConstantToggle = {
	OFF: "off",
	ON: "on",
	TOGGLE: "toggle",
} as const;
export type TypeToggle = typeof ConstantToggle;
export type ValueToggle = (typeof ConstantToggle)[keyof typeof ConstantToggle];

export type ReduxReducer<State, Payload> = CaseReducer<
	State,
	PayloadAction<Payload>
>;
