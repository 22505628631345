import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Row } from "uikit";

import SMSTemplate from "../../../../../../../../../../services/SMSTemplate";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../../../redux/store";
import sendSMSByOrder from "../../../../../../../../../../redux/services/Order/sendSmsByOrder";
import OrderStatus from "../../../../../../../../../../types/OrderStatus";
import Divider from "../../../../../../../../../../components/Divider";
import { Popup } from "../../../../../../../../../../components/common";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../../../access";
import {
	EXECUTED_ORDER_SMS_TEMPLATES,
	REGULAR_ORDER_SMS_TEMPLATES,
} from "../../../components/SMSBadge";

import LeftButton from "./components/LeftButton";
import RightButton from "./components/RightButton";
import PopupContent from "./components/PopupContent";

const popupOffset: Popup.Props["offset"] = { x: 0, y: 37 };

const SMSButton: React.FC<SMSButton.Props> = () => {
	const ACCESS_SECTION = AccessKey.ORDERS;
	const ACCESS_NAME = AccessKey.SMS_BUTTON;

	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const dispatch = useTypedDispatch();

	const allSmsTemplates = useTypedSelector(
		(state) => state.ordersPageReducer.smsTemplates,
	);
	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const smsTemplates = useMemo(() => {
		const isExecutable =
			activeOrder.passengers?.length &&
			activeOrder?.executorToOrder?.length &&
			activeOrder?.status === OrderStatus.EXECUTING;

		if (isExecutable) {
			return allSmsTemplates.filter((template) =>
				EXECUTED_ORDER_SMS_TEMPLATES.includes(template.action),
			);
		}

		return allSmsTemplates.filter((template) =>
			REGULAR_ORDER_SMS_TEMPLATES.includes(template.action),
		);
	}, [allSmsTemplates, activeOrder]);

	const disabled = useMemo(
		() =>
			activeOrder?.closedAt ||
			!activeOrder?.id ||
			!activeOrder?.passengers?.length ||
			!smsTemplates.length,
		[activeOrder, smsTemplates],
	);

	const onSelectSMSTemplate = useCallback(
		(smsTemplate: SMSTemplate.Model) => {
			dispatch(
				sendSMSByOrder(activeOrder.id, {
					action: smsTemplate.action,
				}),
			);

			setIsPopupOpen(false);
		},
		[activeOrder.id, dispatch],
	);

	const isNotAccess = useMemo(
		() =>
			!hasAccess(
				generateAccessName(ACCESS_SECTION, ACCESS_NAME),
				personalRole,
			),
		[ACCESS_SECTION, ACCESS_NAME, personalRole],
	);

	if (isNotAccess) return null;

	return (
		<Popup
			open={isPopupOpen}
			useClickControl={!disabled}
			tracker={
				<Row>
					<LeftButton
						variant="secondary"
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.secondRow.sMSButton.str0",
							) ?? ""
						}
						disabled={disabled}
						active={isPopupOpen}
						tabIndex={-1}
					>
						<Icon id="orders-sms" size={20} />
					</LeftButton>
					<Divider orientation="vertical" size={28} color="#DEE0E2" />
					<RightButton
						variant="secondary"
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.secondRow.sMSButton.str0",
							) ?? ""
						}
						disabled={disabled}
						active={isPopupOpen}
						tabIndex={-1}
					>
						<Icon id="orders-sms-arrow" size={12} />
					</RightButton>
				</Row>
			}
			containerId="page-content"
			trackerId="order-page-header-sms-button"
			offset={popupOffset}
			onChangeOpen={setIsPopupOpen}
		>
			<PopupContent
				smsTemplates={smsTemplates}
				onSelectSMSTemplate={onSelectSMSTemplate}
			/>
		</Popup>
	);
};

declare namespace SMSButton {
	interface Props {}
}

export default SMSButton;
