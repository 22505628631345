import styled from "styled-components";

const Root = styled.div`
	display: flex;
	position: relative;
	height: 100%;
	overflow: hidden;
`;

export default Root;
