/* eslint-disable no-case-declarations */
import React, {
	Dispatch,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { CheckBox } from "uikit";
import { useTranslation } from "react-i18next";
import { RowDataType } from "rsuite-table";

import Tariff from "../../../../../../../../services/Tariff";
import CarClass from "../../../../../../../../services/CarClass";
import TableSettings from "../../../../../../../../components/TableSettings";
import LightTable, {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../components/LightTable";
import {
	StyledGrid,
	StyledGridItem,
	CompaniesByIds,
	TaxiServicesByIds,
	TranslationText,
	CarClassesByIds,
} from "../../../../../../../../components/common";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../../../constants/access";
import tPath from "../../constants/tPath";

import { Columns, columns, defaultColumnsId } from "./constants";

const Content: React.FC<Content.Props> = ({
	tariffData,
	selected,
	carClasses,
	onEdit,
	onChangeSelected,
	onChangeTable,
	editorTable,
}) => {
	const { t } = useTranslation();

	const {
		sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,
		lang,
	} = useTableOptions({
		itemLimit: 2,
		heightRow: 44,
		value: editorTable,
		setValue: onChangeTable,
	});

	// eslint-disable-next-line no-param-reassign
	columns.forEach((col) => (col.label = t(`${tPath}.headerCells.${col.id}`)));
	const [columnsId, setColumnsId] = useState(defaultColumnsId);

	const [companyLength, setCompanyLength] = useState<number>(0);
	const [taxiServiceLength, setTaxiServiceLength] = useState<number>(0);
	const [tariffDataLength, setTariffDataLength] = useState<number>(0);
	const [tariffDataMap, setTariffDataMap] = useState<
		Map<string, Tariff.Model[]>
	>(new Map());

	useEffect(() => {
		if (tariffData.length !== tariffDataLength) {
			setTariffDataLength(tariffData.length);
			setTariffDataMap(new Map());
		}
	}, [tariffData.length, tariffDataLength]);

	const carClassesMap = useMemo(
		() =>
			carClasses.reduce(
				(acc, carClass) => ({
					...acc,
					[carClass.id]: carClass.name?.[lang],
				}),
				{},
			),
		[carClasses, lang],
	);

	const sortTariffData = useMemo<Tariff.Model[]>(() => {
		if (!tariffDataLength) return [];
		const key = `${sort.dataKey}_${sort.sortType}`;
		const exist = tariffDataMap.get(key);
		if (exist) return exist;
		if (sort.sortType === "asc") {
			if (sort.dataKey === Columns.NAME) {
				const sort = [...tariffData].sort((prev, next) => {
					const prevName = prev.name[lang];
					const nextName = next.name[lang];
					if (!prevName || !nextName) return 0;
					if (prevName < nextName) return -1;
					return prevName > nextName ? 1 : 0;
				});

				tariffDataMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === Columns.AUTO_CLASSES) {
				const sort = [...tariffData].sort((prev, next) => {
					const prevName = prev.carClassIds
						.map((id) => carClassesMap[id])
						.join(",").length;
					const nextName = next.carClassIds
						.map((id) => carClassesMap[id])
						.join(",").length;
					if (!prevName || !nextName) return 0;
					if (prevName < nextName) return -1;
					return prevName > nextName ? 1 : 0;
				});
				tariffDataMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === Columns.BRANCHES) {
				const sort = [...tariffData].sort((prev, next) => {
					const prevName = prev.taxiServices
						.map(
							(taxiService) =>
								taxiService?.settlement?.[lang] || "",
						)
						.join(",").length;
					const nextName = next.taxiServices
						.map(
							(taxiService) =>
								taxiService?.settlement?.[lang] || "",
						)
						.join(",").length;
					if (!prevName || !nextName) return 0;
					if (prevName > nextName) return -1;
					return prevName < nextName ? 1 : 0;
				});
				tariffDataMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === Columns.COMPANIES) {
				const sort = [...tariffData].sort((prev, next) => {
					const prevName = prev.taxiServices
						.map(
							(taxiService) =>
								taxiService?.company?.name?.[lang] || "",
						)
						.join(",").length;
					const nextName = next.taxiServices
						.map(
							(taxiService) =>
								taxiService?.company?.name?.[lang] || "",
						)
						.join(",").length;
					if (!prevName || !nextName) return 0;
					if (prevName > nextName) return -1;
					return prevName < nextName ? 1 : 0;
				});

				tariffDataMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === Columns.TARIFF_GROUP_TYPE) {
				const sort = [...tariffData].sort((prev, next) => {
					let prevGroup = "";
					if (prev.default && prev.root) {
						prevGroup = "global";
					} else if (prev.default && !prev.root) {
						prevGroup = "base";
					} else prevGroup = "custom";

					let nextGroup = "";
					if (next.default && next.root) {
						nextGroup = "global";
					} else if (next.default && !next.root) {
						nextGroup = "base";
					} else nextGroup = "custom";

					if (!nextGroup || !prevGroup) return 0;
					if (nextGroup > prevGroup) return -1;
					return nextGroup < prevGroup ? 1 : 0;
				});

				tariffDataMap.set(key, sort);
				return sort;
			}
		}

		if (sort.sortType === "desc") {
			if (sort.dataKey === Columns.NAME) {
				if (exist) return exist;
				const sort = [...tariffData].sort((prev, next) => {
					const prevName = next.name[lang];
					const nextName = prev.name[lang];
					if (!prevName || !nextName) return 0;
					if (prevName < nextName) return -1;
					return prevName > nextName ? 1 : 0;
				});
				tariffDataMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === Columns.AUTO_CLASSES) {
				const sort = [...tariffData].sort((prev, next) => {
					const prevName = prev.carClassIds
						.map((id) => carClassesMap[id])
						.join(",").length;
					const nextName = next.carClassIds
						.map((id) => carClassesMap[id])
						.join(",").length;
					if (!prevName || !nextName) return 0;
					if (prevName > nextName) return -1;
					return prevName < nextName ? 1 : 0;
				});
				tariffDataMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === Columns.BRANCHES) {
				const sort = [...tariffData].sort((prev, next) => {
					const prevName = prev.taxiServices
						.map(
							(taxiService) =>
								taxiService?.settlement?.[lang] || "",
						)
						.join(",").length;
					const nextName = next.taxiServices
						.map(
							(taxiService) =>
								taxiService?.settlement?.[lang] || "",
						)
						.join(",").length;
					if (!prevName || !nextName) return 0;
					if (prevName < nextName) return -1;
					return prevName > nextName ? 1 : 0;
				});
				tariffDataMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === Columns.COMPANIES) {
				const sort = [...tariffData].sort((prev, next) => {
					const prevName = prev.taxiServices
						.map(
							(taxiService) =>
								taxiService?.company?.name?.[lang] || "",
						)
						.join(",").length;
					const nextName = next.taxiServices
						.map(
							(taxiService) =>
								taxiService?.company?.name?.[lang] || "",
						)
						.join(",").length;
					if (!prevName || !nextName) return 0;
					if (prevName < nextName) return -1;
					return prevName > nextName ? 1 : 0;
				});
				tariffDataMap.set(key, sort);
				return sort;
			}
			if (sort.dataKey === Columns.TARIFF_GROUP_TYPE) {
				const sort = [...tariffData].sort((prev, next) => {
					let prevGroup = "";
					if (prev.default && prev.root) {
						prevGroup = "global";
					} else if (prev.default && !prev.root) {
						prevGroup = "base";
					} else prevGroup = "custom";

					let nextGroup = "";
					if (next.default && next.root) {
						nextGroup = "global";
					} else if (next.default && !next.root) {
						nextGroup = "base";
					} else nextGroup = "custom";
					if (!nextGroup || !prevGroup) return 0;
					if (nextGroup < prevGroup) return -1;
					return nextGroup > prevGroup ? 1 : 0;
				});

				tariffDataMap.set(key, sort);
				return sort;
			}
		}
		return tariffData;
	}, [
		tariffDataLength,
		sort.dataKey,
		sort.sortType,
		tariffDataMap,
		tariffData,
		lang,
		carClassesMap,
	]);

	useMemo(() => {
		const companySet = new Set();
		carClasses.forEach((item) => {
			const length = item?.taxiServices?.length || 0;
			if (length > taxiServiceLength) {
				setTaxiServiceLength(length);
			}
			item?.taxiServices?.forEach((item) => {
				if (item.company?.id) {
					companySet.add(item.company.id);
				}
			});
			if (companySet.size > companyLength)
				setCompanyLength(companySet.size);
		});
	}, [carClasses, companyLength, taxiServiceLength]);

	const updateSelected = useCallback(
		(item: RowDataType<Tariff.Model>, force = false) => {
			const model = item as Tariff.Model;
			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					onChangeSelected(selected.filter((id) => model.id !== id));
				} else {
					onChangeSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? onChangeSelected([])
					: onChangeSelected([model.id]);
			}
		},
		[ctrlPressed, onChangeSelected, selected],
	);

	const handleOnRowClick = useCallback(
		(tariff: any) => {
			if (tariff) {
				if (ctrlPressed) updateSelected(tariff);
				else {
					const exist = selected.find((item) => item === tariff.id);
					if (exist && selected.length <= 1) onChangeSelected([]);
					else onChangeSelected([tariff.id]);
				}
			}
		},
		[ctrlPressed, updateSelected, selected, onChangeSelected],
	);

	const renderColumns = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						width={column.width || 150}
						flexGrow={column.width ? 0 : 1}
						sortable
						resizable
					>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<TranslationText text={[column.id, column.label]} />
							{!column.label && <CheckBox />}
						</LightTable.HeaderCell>
						<LightTable.Cell
							style={{ padding: "0 25px" }}
							dataKey={column.id}
							verticalAlign="middle"
							fullText
						>
							{(tariff) => {
								const model = tariff as Tariff.Model;
								switch (columnId) {
									case Columns.NAME:
										return tariff.name[lang];

									case Columns.COMPANIES:
										return (
											<CompaniesByIds
												taxiServiceIds={
													model.taxiServiceIds
												}
												lang={lang}
											/>
										);

									case Columns.BRANCHES:
										return (
											<TaxiServicesByIds
												taxiServiceIds={
													model.taxiServiceIds
												}
												lang={lang}
											/>
										);
									case Columns.TARIFF_GROUP_TYPE:
										if (tariff.root && tariff.default)
											return t(
												`${tPath}.mainModal.global`,
											);
										if (!tariff.root && tariff.default)
											return t(`${tPath}.mainModal.base`);
										return t(`${tPath}.mainModal.custom`);

									case Columns.AUTO_CLASSES:
										return (
											<CarClassesByIds
												carClassIds={tariff.carClassIds}
												lang={lang}
												taxiServiceIds={
													model.taxiServiceIds
												}
											/>
										);

									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnsId, lang, t],
	);

	const getRowClassName = useCallback(
		(rowData) =>
			rowData && selected.includes(rowData.id) ? "selected" : "",
		[selected],
	);

	const handleAllCheckedUpdate = useCallback(
		(isAllSelected) => {
			if (isAllSelected) {
				onChangeSelected(tariffData.map(({ id }) => id));
			} else {
				onChangeSelected([]);
			}
		},
		[onChangeSelected, tariffData],
	);

	const areas = useMemo(() => `'table' 'settings' `, []);

	return (
		<StyledGrid
			areas={areas}
			rows="1fr 32px"
			w="100%"
			h="100%"
			br="5px"
			over="hidden"
		>
			<StyledGridItem
				area="table"
				w="100%"
				h="100%"
				tabIndex={-1}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
			>
				<LightTable
					ref={tableRef}
					data={sortTariffData}
					virtualized
					fillHeight
					headerHeight={44}
					rowHeight={44}
					shouldUpdateScroll={false}
					rowClassName={getRowClassName}
					onRowClick={handleOnRowClick}
					onRowDoubleClick={onEdit}
					accessName={ARR_ACCESS_PART_KEY[TabKeys.MAIN_TARIFFS]}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onScroll={onScroll}
					onSortColumn={onSort}
				>
					<LightTable.Column verticalAlign="middle" width={50}>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							<CheckBox
								value={
									selected.length === tariffData.length &&
									tariffData.length !== 0
								}
								onChange={handleAllCheckedUpdate}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(tariff) => (
								<CheckBox
									value={selected.includes(tariff.id)}
									onChange={() => {
										updateSelected(tariff, true);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{renderColumns}
				</LightTable>
			</StyledGridItem>
			<StyledGridItem area="settings" w="100%" h="100%" zi="1">
				<TableSettings
					value={columnsId}
					defaultValue={defaultColumnsId}
					columns={columns}
					onChange={setColumnsId}
				/>
			</StyledGridItem>
		</StyledGrid>
	);
};

declare namespace Content {
	interface Props {
		tariffData: Tariff.Model[] | [];
		selected: number[];
		onEdit?: Dispatch<Tariff.Modified>;
		onChangeSelected: Dispatch<number[]>;
		carClasses: CarClass.Model[];
		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
