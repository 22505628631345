import {
	generateAccessName,
	AccessKey,
} from "../../../../../../../../../access";

/**
 * Base keys of the access sections on this page.
 */
const ACCESS_SECTION: AccessKey[] = [AccessKey.ORDERS];

/**
 * Enum representing special keys for each tab on this page.
 * These keys are used to uniquely identify and manage individual tabs.
 */
enum TabKeys {
	LIVE = "live",
	PRELIMINARY = "preliminary",
	EXECUTING = "executing",
	ALL = "all",
	CLOSED = "closed",
	SEARCH_CAR = "searchCar",
	SEARCH_EXECUTOR = "searchExecutor",
	SEARCH_ORDER = "searchOrder",
}

/**
 * Constructs an array of access addresses by combining base access addresses
 * with the tab-specific access keys. This builds the full set of addresses
 * needed to manage access from the page level to the specific tab.
 *
 * @param key - Tab-specific access keys.
 * @returns Array of access addresses including base and tab-specific addresses.
 */
const generateAccessSection = (key: AccessKey): AccessKey[] => [
	...ACCESS_SECTION,
	key,
];

/**
 * Maps each tab on the page to its corresponding array of access addresses.
 * This container is used for managing access control not only for the tabs themselves,
 * but also for internal elements such as buttons within these tabs.
 * Each entry provides a set of access addresses specific to the tab.
 */
const ARR_ACCESS_PART_KEY: Record<TabKeys, AccessKey[]> = {
	[TabKeys.LIVE]: generateAccessSection(AccessKey.LIVE_ORDERS),
	[TabKeys.PRELIMINARY]: generateAccessSection(AccessKey.PRELIMINARY_ORDERS),
	[TabKeys.EXECUTING]: generateAccessSection(AccessKey.EXECUTING_ORDERS),
	[TabKeys.ALL]: generateAccessSection(AccessKey.ALL_ORDERS),
	[TabKeys.CLOSED]: generateAccessSection(AccessKey.CLOSED_ORDERS),
	[TabKeys.SEARCH_CAR]: generateAccessSection(AccessKey.SEARCH_CAR),
	[TabKeys.SEARCH_EXECUTOR]: generateAccessSection(AccessKey.SEARCH_EXECUTOR),
	[TabKeys.SEARCH_ORDER]: generateAccessSection(AccessKey.SEARCH_ORDER),
};

/**
 * Type representing the access names for each tab.
 */
type TabAccessNamesType = {
	[key in TabKeys]: string;
};

/**
 * Generates access names from arrays of access keys for each tab.
 * This mapping creates a unique access name for each tab, which is used
 * to determine whether the tab is accessible or not. Each access name represents
 * the combined access keys for the tab.
 *
 * @returns TabAccessNamesType - An object mapping each tab key to its unique access name
 */
const TabAccessNames: TabAccessNamesType = Object.fromEntries(
	Object.entries(ARR_ACCESS_PART_KEY).map(([key, section]) => [
		key,
		generateAccessName(...section),
	]),
) as TabAccessNamesType;

const AccessKeysOrderTabs = [
	TabAccessNames[TabKeys.LIVE],
	TabAccessNames[TabKeys.PRELIMINARY],
	TabAccessNames[TabKeys.EXECUTING],
	TabAccessNames[TabKeys.ALL],
	TabAccessNames[TabKeys.CLOSED],
];

export { TabKeys, TabAccessNames, ARR_ACCESS_PART_KEY, AccessKeysOrderTabs };
