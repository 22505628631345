import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import account from "../../reducers/account";

interface Props {
	limit?: number;
	sortType?: string;
}

export default function getDispatchers(props?: Props) {
	return async (dispatch) => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		const data = props
			? await createRPCQuery(() =>
					prpcow?.theirsModel.dispatcher.getAll({
						limit: props?.limit,
					}),
			  )
			: await createRPCQuery(() =>
					prpcow?.theirsModel.dispatcher.getAll(),
			  );

		if (!data) return null;

		return dispatch(account.actions.setDispatchers(data?.items));
	};
}
