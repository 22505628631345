import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { CheckBox, Row, ToggleButton } from "uikit";
import { useTranslation } from "react-i18next";
import { RowDataType } from "rsuite-table";
import { clone } from "lodash";

import ExecutorGroup from "../../../../../../../../services/ExecutorGroup";
import KeyBinder from "../../../../../../../../services/KeyBinder";
import LightTable, {
	useTableOptions,
} from "../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../components/TableSettings";
import { useExecutorTeamsContext } from "../../../../../context";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../../../constants/access";
import translationPath from "../../constants/translationPath";

import { Columns, columns, defaultColumnsId } from "./constants";
import { Root, Companies } from "./components";

const Content: React.FC<Content.Props> = ({
	selected,
	setSelected,
	onEdit,
	saveHandler,
}) => {
	const { t } = useTranslation();

	const { language, models, onChangeTable, editorTable } =
		useExecutorTeamsContext();

	const {
		sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,
	} = useTableOptions({
		itemLimit: 2,
		heightRow: 46,
		value: editorTable,
		setValue: onChangeTable,
	});

	// eslint-disable-next-line no-param-reassign
	columns.forEach(
		(col) => (col.label = t(`${translationPath}.headerCells.${col.id}`)),
	);
	const [columnsId, setColumnsId] = useState(defaultColumnsId);

	const updateSelected = useCallback(
		(item: RowDataType<ExecutorGroup.Model>, force = false) => {
			const model = item as ExecutorGroup.Model;
			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					setSelected(selected.filter((id) => model.id !== id));
				} else {
					setSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? setSelected([])
					: setSelected([model.id]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				setSelected(newSelected);
			} else
				setSelected(selected.filter((selectedId) => selectedId !== id));
		},
		[selected, setSelected],
	);

	const tableOnRowClick = useCallback(
		(rowData) =>
			KeyBinder.isControlPressed
				? setItemSelection(rowData.id, !selected.includes(rowData.id))
				: setSelected(
						selected.includes(rowData.id) ? [] : [rowData.id],
				  ),
		[selected, setItemSelection, setSelected],
	);

	const renderColumns = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						width={column.width}
						flexGrow={column.width ? 0 : 1}
						sortable
						resizable
					>
						<LightTable.HeaderCell>
							{t([
								`${translationPath}.headerCells.${column.id}`,
								"",
							])}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							dataKey={column.id}
						>
							{(template) => {
								switch (columnId) {
									case Columns.ACTIVE:
										return (
											<div
												onClick={(event) =>
													event.stopPropagation()
												}
												style={{
													maxWidth: "max-content",
												}}
											>
												<ToggleButton.ToggleButton
													value={template?.active}
													onChange={(newActive) => {
														saveHandler(
															{
																...template,
																active: newActive,
															},
															true,
														);
													}}
												/>
											</div>
										);

									case Columns.NAME:
										return template?.name?.[language] || "";

									case Columns.COMPANIES:
										return (
											<Companies
												taxiServices={
													template.taxiServices
												}
												language={language}
											/>
										);

									case Columns.BRANCHES:
										return (
											<Row align="center" gaps="8px*">
												{template.taxiServices?.map(
													(taxiService, index) => (
														<Row
															align="center"
															key={taxiService.id}
														>
															{
																taxiService
																	?.settlement?.[
																	language
																]
															}
															{index !==
																template
																	.taxiServices
																	.length -
																	1 && ","}
														</Row>
													),
												)}
											</Row>
										);
									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnsId, language, saveHandler, t],
	);

	const getRowClassName = useCallback(
		(rowData) =>
			rowData && selected.includes(rowData.id) ? "selected" : "",
		[selected],
	);

	const handleAllCheckedUpdate = useCallback(
		(isAllSelected) => {
			if (isAllSelected) {
				setSelected(models.map(({ id }) => id));
			} else {
				setSelected([]);
			}
		},
		[setSelected, models],
	);

	return (
		<Root sizes="1fr auto!" onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
			<LightTable
				ref={tableRef}
				data={models}
				virtualized
				fillHeight
				rowHeight={46}
				headerHeight={44}
				shouldUpdateScroll={false}
				rowClassName={getRowClassName}
				onRowClick={tableOnRowClick}
				onRowDoubleClick={onEdit}
				accessName={ARR_ACCESS_PART_KEY[TabKeys.EXECUTOR_TEAMS]}
				sortColumn={sort?.dataKey}
				sortType={sort?.sortType}
				onScroll={onScroll}
				onSortColumn={onSort}
			>
				<LightTable.Column verticalAlign="middle" width={32}>
					<LightTable.HeaderCell>
						<CheckBox
							value={
								selected.length === models.length &&
								models.length !== 0
							}
							onChange={handleAllCheckedUpdate}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell verticalAlign="middle">
						{(rowData) => (
							<CheckBox
								value={selected.includes(rowData.id)}
								onChange={() => {
									updateSelected(rowData, true);
								}}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>
				{renderColumns}
			</LightTable>
			<TableSettings
				value={columnsId}
				defaultValue={defaultColumnsId}
				columns={columns}
				onChange={setColumnsId}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		selected: number[];
		setSelected: Dispatch<number[]>;
		onEdit?: Dispatch<any>;
		saveHandler: (x: any, y: boolean) => Promise<void>;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
