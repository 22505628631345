import React, {
	useState,
	useEffect,
	useCallback,
	useMemo,
	Fragment,
} from "react";
import { isString } from "lodash";
import { useTranslation } from "react-i18next";
import { CheckBox, Icon, theme } from "uikit";
import styled from "styled-components";

import {
	ACCESS_TO_THE_COMPONENT,
	AccessCompletedBranch,
	DisplayFields,
	AccessKey,
	ACCESS_TRANSLATE,
} from "../../../../../../../../../../../../constants/access";
import { StyledColumn } from "../../../../../../../../../../../../components/common";

import {
	Divider,
	Root,
	Button,
	Label,
	Header,
	NumericDial,
	Separator,
} from "./components";
import reservedKeys from "./constants";

const LeftPane = styled.div`
	flex: 1;
	margin: 10px;
	overflow-y: auto;
`;

const RightPane = styled.div`
	flex: 1;
	margin: 10px;
	overflow-y: auto;
`;

// const Separator = styled.div<{ level: number }>`
// 	border-top: 1px dashed black;
// 	margin: 5px 0;
// 	margin-left: ${(props) => props.level}px;
// `;

const ExpandableSection = styled.div<{ isOpen: boolean; level: number }>`
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-left: ${(props) => props.level}px;
`;

const IconWrapper = styled(Icon)<{ isOpen: boolean }>`
	transform: ${(props) =>
		props.isOpen ? "rotate(-180deg)" : "rotate(0deg)"};
	transition: transform 0.3s ease;
	margin-left: 8px;
`;

const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const Column = styled(StyledColumn)<{ marginLeft: number }>`
	margin-left: ${(props) => props.marginLeft}px;
	margin-top: 20px;
`;

const SelectedItem = styled.div<{ marginLeft: number }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
	margin-left: ${(props) => props.marginLeft}px;
`;

const TreeSelect: React.FC<TreeSelect.Props> = ({
	value,
	onChange,
	isMainRole,
	disabled,
}) => {
	const [t] = useTranslation();
	const [access, setAccess] = useState<DisplayFields>(
		value || ACCESS_TO_THE_COMPONENT,
	);
	const [openSections, setOpenSections] = useState<string[]>([]);
	const [selectedItems, setSelectedItems] = useState<string[]>([]);
	const [itemLevels, setItemLevels] = useState<{ [key: string]: number }>({});

	const arraysEqual = (arr1: string[], arr2: string[]): boolean => {
		if (arr1.length !== arr2.length) return false;
		return arr1.every((value, index) => value === arr2[index]);
	};

	const isReservedKey = useCallback(
		(path: string[]): boolean =>
			reservedKeys.some((reservedPath) =>
				arraysEqual(reservedPath, path),
			),
		[],
	);

	//* Function to initialize the selected elements in the order they are displayed in the tree
	const initializeSelectedItems = useCallback(
		(tree: DisplayFields, path: string[] = []): string[] => {
			let orderedItems: string[] = [];
			Object.keys(tree).forEach((key) => {
				const currentPath = [...path, key];
				const value = tree[key];

				if (key === AccessKey.SHOW && value) {
					orderedItems.push(path.join("."));
				} else if (typeof value === "object") {
					orderedItems = [
						...orderedItems,
						...initializeSelectedItems(
							value as DisplayFields,
							currentPath,
						),
					];
				}
			});
			return orderedItems;
		},
		[],
	);

	useEffect(() => {
		const orderedItems = initializeSelectedItems(access);
		setSelectedItems(orderedItems);
	}, [access, initializeSelectedItems]);

	useEffect(() => {
		onChange(access);
	}, [access, onChange]);

	const getItemLevel = (item: string): number => item.split(".").length - 1;

	const toggleSection = (path: string) => {
		setOpenSections((prev) =>
			prev.includes(path)
				? prev.filter((section) => section !== path)
				: [...prev, path],
		);
	};

	const updateItemAndChildren = (item: any, checked: boolean) => {
		if (typeof item === "object") {
			item[AccessKey.SHOW] = checked;
			updateAllChildren(item, checked);
		}
	};

	const updateAllChildren = (item: any, checked: boolean) => {
		if (typeof item === "object") {
			for (const key in item) {
				if (key === AccessKey.SHOW) {
					item[key] = checked;
				} else if (typeof item[key] === "object") {
					updateAllChildren(item[key], checked);
				}
			}
		}
	};

	const handleCheckboxChange = (path: string[], checked: boolean) => {
		if (isReservedKey(path)) {
			return;
		}

		const newAccess: DisplayFields = JSON.parse(JSON.stringify(access));
		let currentLevel = newAccess;

		for (let i = 0; i < path.length; i++) {
			const key = path[i];
			if (i === path.length - 1) {
				updateItemAndChildren(currentLevel[key], checked);
			} else {
				currentLevel = currentLevel[key];
			}
		}

		if (JSON.stringify(newAccess) !== JSON.stringify(access)) {
			setAccess(newAccess);
		}

		const orderedItems = initializeSelectedItems(newAccess);
		setSelectedItems(orderedItems);
	};

	const hasOtherProperties = (obj: DisplayFields) =>
		Object.keys(obj).some(
			(key) => key !== AccessKey.SHOW && key !== AccessKey.METADATA,
		);

	const handleRemoveItem = (item: string) => {
		const path = item.split(".");
		handleCheckboxChange(path, false);
	};

	const containsDot = (str: string): boolean => str.includes(".");

	/* Text */

	const cleanStringFromBadSigns = useCallback((str: string): string => {
		if (!isString(str)) return "";

		return str.trim().replace(/\($/, "");
	}, []);

	const getTextAfterLastDot = useCallback((str: string): string => {
		if (!isString(str)) return "";
		const lastDotIndex = str.lastIndexOf(".");

		return lastDotIndex !== -1 ? str.substring(lastDotIndex + 1) : str;
	}, []);

	const findingTextBehindAnAddress = useCallback(
		(key: string): string => {
			if (!isString(key)) return "";

			const textTranslate = t(ACCESS_TRANSLATE?.[key] ?? key) ?? key;

			return cleanStringFromBadSigns(textTranslate);
		},
		[cleanStringFromBadSigns, t],
	);

	const findingTextBehindAnAddressWithAfterLastDot = useCallback(
		(key: string): string => {
			if (!isString(key)) return "";

			const textTranslate =
				t(
					ACCESS_TRANSLATE?.[getTextAfterLastDot(key)] ??
						getTextAfterLastDot(key),
				) ?? getTextAfterLastDot(key);

			return cleanStringFromBadSigns(textTranslate);
		},
		[cleanStringFromBadSigns, getTextAfterLastDot, t],
	);

	/* Text End */

	const getDisabledStatus = (path: string[], checked: boolean): boolean => {
		// if (path.length === 0) return false;
		// if (path.length === 1) return true;

		let parent: DisplayFields | AccessCompletedBranch | undefined = access;

		for (const key of path.slice(0, -1)) {
			if (parent && typeof parent === "object" && key in parent) {
				parent = parent[key];
			} else {
				return false;
			}
		}

		if (parent && typeof parent === "object" && AccessKey.SHOW in parent) {
			return !parent[AccessKey.SHOW];
		}

		return false;
	};

	const renderAccessTree = (tree: DisplayFields, path: string[] = []) =>
		Object.keys(tree).map((key) => {
			const currentPath = [...path, key];

			if (isReservedKey(currentPath)) {
				return null;
			}

			const value = tree[key];

			if (
				[AccessKey.SHOW, AccessKey.METADATA].includes(key as AccessKey)
			) {
				return null;
			}

			const isChecked =
				typeof value === "boolean"
					? value
					: value?.[AccessKey.SHOW] || false;
			const isExpandable =
				typeof value === "object" && hasOtherProperties(value);
			const currentPathString = currentPath.join(".");
			const isDisabled = getDisabledStatus(currentPath, isChecked);

			const handleParentCheckboxChange = (checked: boolean) => {
				handleCheckboxChange(currentPath, checked);
			};

			const textTranslate = findingTextBehindAnAddress(key);

			const isOpen = openSections.includes(currentPathString);

			return (
				<Column key={key} marginLeft={path.length ? 25 : 0}>
					<CheckboxContainer>
						<CheckBox
							disabled={isMainRole || disabled || isDisabled}
							value={!!isChecked}
							onChange={(selected) => {
								handleParentCheckboxChange(selected);
							}}
						/>
						{isExpandable ? (
							<ExpandableSection
								isOpen={isOpen}
								level={path.length}
								onClick={() => toggleSection(currentPathString)}
							>
								<Label selected={!!isChecked}>
									{textTranslate}
								</Label>
								<IconWrapper
									id="down-arrow"
									size={10}
									colors={[theme.colors.primary]}
									isOpen={isOpen}
								/>
							</ExpandableSection>
						) : (
							<Label selected={!!isChecked}>
								{textTranslate}
							</Label>
						)}
					</CheckboxContainer>
					{isExpandable &&
						openSections.includes(currentPathString) && (
							<div>
								{renderAccessTree(
									value as DisplayFields,
									currentPath,
								)}
							</div>
						)}
				</Column>
			);
		});

	const headerOnClearAllTree = () => {
		const newAccess = { ...access };

		const setAllShowFalse = (
			obj: AccessCompletedBranch,
			path: string[] = [],
		) => {
			Object.keys(obj).forEach((key) => {
				const currentPath = [...path, key];

				if (!isReservedKey(currentPath)) {
					if (key === AccessKey.SHOW) {
						obj[key] = false;
					} else if (typeof obj[key] === "object") {
						setAllShowFalse(
							obj[key] as AccessCompletedBranch,
							currentPath,
						);
					}
				}
			});
		};

		setAllShowFalse(newAccess);
		setAccess(newAccess);
		setSelectedItems([]);
	};

	const getItemMetadata = (item: string): string | null => {
		const path = item.split(".");

		let currentLevel: any = access;
		for (const key of path) {
			if (
				currentLevel &&
				typeof currentLevel === "object" &&
				key in currentLevel
			) {
				currentLevel = currentLevel[key];
			} else {
				return null;
			}
		}

		return currentLevel?.[AccessKey.METADATA] ?? null;
	};

	const filteredSelectedItems = useMemo(() => {
		const filtered = selectedItems?.filter(
			(item) => !isReservedKey(item.split(".")),
		);
		const levels = filtered.reduce((acc, item) => {
			acc[item] = getItemLevel(item);
			return acc;
		}, {} as { [key: string]: number });

		setItemLevels(levels);
		return filtered;
	}, [isReservedKey, selectedItems]);

	const renderSelectedItems = () => {
		let previousMetadataKey = "";

		return filteredSelectedItems.map((item, index) => {
			const currentLevel = itemLevels[item];
			const marginLeft = currentLevel * 20;

			const itemMetadata = getItemMetadata(item);
			const metadataKey = itemMetadata || "default";

			const showMetadataSeparator = metadataKey !== previousMetadataKey;
			previousMetadataKey = metadataKey;

			const titleTranslate = itemMetadata
				? findingTextBehindAnAddressWithAfterLastDot(itemMetadata)
				: undefined;
			const textTranslate =
				findingTextBehindAnAddressWithAfterLastDot(item);

			return (
				<Fragment key={item}>
					{showMetadataSeparator && index > 0 && (
						<Separator text={titleTranslate} />
					)}
					{containsDot(item) ? (
						<SelectedItem marginLeft={marginLeft}>
							<Label selected={true}>{textTranslate}</Label>
							<Button
								disabled={isMainRole || disabled}
								onClick={() => handleRemoveItem(item)}
							>
								<Icon
									id="trash"
									size={20}
									colors={[
										theme.colors.text_hovered_placeholder,
									]}
								/>
							</Button>
						</SelectedItem>
					) : (
						<Header
							label={
								t(ACCESS_TRANSLATE?.[item] ?? item) ??
								item ??
								""
							}
						/>
					)}
				</Fragment>
			);
		});
	};

	return (
		<Root>
			<LeftPane>{renderAccessTree(access)}</LeftPane>
			<Divider />
			<RightPane>
				<NumericDial
					disabled={isMainRole || disabled}
					count={filteredSelectedItems.length ?? 0}
					onClearAllTree={headerOnClearAllTree}
				/>
				{renderSelectedItems()}
			</RightPane>
		</Root>
	);
};

declare namespace TreeSelect {
	interface Props {
		value: DisplayFields;
		onChange: (newAccess: DisplayFields) => void;
		isMainRole: boolean;
		disabled: boolean;
	}
}

export default TreeSelect;
