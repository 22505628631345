/* eslint-disable no-shadow */
import React, {
	Dispatch,
	ReactElement,
	useCallback,
	useMemo,
	useRef,
} from "react";
import { CheckBox, Column, Table } from "uikit";
import { RowDataType, SortType } from "rsuite-table";
import { clone, isUndefined } from "lodash";
import { UseTranslationResponse, useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-unresolved
import { DefaultNamespace } from "react-i18next/TransWithoutContext";
import moment from "moment";

import KeyBinder from "../../../../../../../../../../services/KeyBinder";
import CustomerRate from "../../../../../../../../../../services/CustomerRate";
import Language from "../../../../../../../../../../services/Language";
import {
	useVisibleColumns,
	useWidths,
} from "../../../../../../../../../../hooks/useTableSettings";
import { getColumnsModeTwo } from "../../../../../../../../../../utils";
import LightTable from "../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../components/TableSettings";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

interface ColumnContext {
	t: UseTranslationResponse<DefaultNamespace, undefined>["t"];
	language: Language;
	width: number;
	onResize: (width?: number, columnId?: string) => void;
}

type CellContent = (rowData: RowDataType, rowIndex?: number) => React.ReactNode;

const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
	name: ({ language, width, onResize, t }) => (
		<LightTable.Column
			width={width}
			sortable
			resizable
			onResize={onResize}
			fullText
		>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.content.modelTable.str150",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="name">
				{((item) => item.name[language]) as CellContent}
			</LightTable.Cell>
		</LightTable.Column>
	),

	mode: ({ t, width, onResize }) => (
		<LightTable.Column
			width={width}
			sortable
			resizable
			onResize={onResize}
			fullText
		>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.content.modelTable.str151",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="mode">
				{
					((item: CustomerRate.Model) =>
						t(`customerRates.modes.${item.mode}`)) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	companies: ({ language, t }) => (
		<LightTable.Column width={190} resizable sortable fullText>
			<LightTable.HeaderCell>
				{t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.content.modelTable.str100",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell fullText dataKey="companies">
				{
					((item: CustomerRate.Model) =>
						item.companies?.map(
							(company) => company.name[language],
						)) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	createdAt: ({ width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.settings.pages.finances.tabs.customerTariffPlans.content.modelTable.str152",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="createdAt">
				{
					((item: CustomerRate.Model) =>
						moment(item.createdAt).format(
							"DD.MM.YYYY",
						)) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),
};

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,
	sort,

	loading,
	data,
	language,

	onChangeSelected,
	onChangeSort,

	onEdit,
	onLoadMore,
}) => {
	const { t } = useTranslation();

	const defaultColumnIds: Parameters<typeof getColumnsModeTwo>[1] = useMemo(
		() => ["name", "mode", "companies", "createdAt"],
		[],
	);

	const columns = useMemo(
		() => getColumnsModeTwo(t, defaultColumnIds),
		[t, defaultColumnIds],
	);

	const { columnIds, setColumnIds } = useVisibleColumns(
		"settings.mutualSettlements.customerTariffs",
		"customerTariff",
	);
	const { widths, setWidths } = useWidths(
		"settings.mutualSettlements.customerTariffs",
		"customerTariff",
	);

	const canLoadMoreRef = useRef(true);
	const tableRef = useRef<Table.Ref | null>(null);

	const tableRowClassName = useCallback(
		(rowData) => {
			if (selected.includes(rowData?.id ?? "")) return "selected";

			if (!rowData?.active) return "not-active";

			return "";
		},
		[selected],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore]);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				onChangeSelected(newSelected);
			} else
				onChangeSelected(
					selected.filter((selectedId) => selectedId !== id),
				);
		},
		[onChangeSelected, selected],
	);

	const tableOnRowClick = useCallback(
		(rowData) =>
			KeyBinder.isControlPressed
				? setItemSelection(rowData.id, !selected.includes(rowData.id))
				: onChangeSelected(
						selected.includes(rowData.id) ? [] : [rowData.id],
				  ),
		[onChangeSelected, selected, setItemSelection],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<LightTable
				ref={tableRef}
				fillHeight
				virtualized
				shouldUpdateScroll={false}
				headerHeight={44}
				rowHeight={44}
				sortColumn={sort.column}
				sortType={sort.type}
				loading={loading}
				data={data}
				rowClassName={tableRowClassName}
				onScroll={tableOnScroll}
				onRowClick={tableOnRowClick}
				onRowDoubleClick={onEdit}
				accessName={ARR_ACCESS_PART_KEY[TabKeys.CUSTOMER_TARIFF_PLANS]}
				onSortColumn={(column, type) => onChangeSort({ column, type })}
			>
				<LightTable.Column width={36}>
					<LightTable.HeaderCell verticalAlign="middle">
						<CheckBox
							value={
								selected.length === data.length &&
								data.length !== 0
							}
							onChange={(value) =>
								onChangeSelected(
									value ? data.map((item) => item.id) : [],
								)
							}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell verticalAlign="middle">
						{(rowData) => (
							<CheckBox
								value={selected.includes(rowData.id)}
								onChange={(selected) =>
									KeyBinder.isControlPressed
										? setItemSelection(rowData.id, selected)
										: onChangeSelected(
												selected ? [rowData.id] : [],
										  )
								}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>

				{columnIds.map((columnId) =>
					Columns[columnId]({
						t,
						language,
						width: widths[columnId],
						onResize: (width, columnId) => {
							if (isUndefined(width) || isUndefined(columnId))
								return;

							setWidths({
								...widths,
								[columnId]: width,
							});
						},
					}),
				)}
			</LightTable>
			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		selected: number[];
		sort: Sort;

		loading: boolean;
		data: (unknown & { id: number })[];
		language: Language;

		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;

		onEdit: Dispatch<number>;
		onLoadMore: () => void;
	}
}

export default ModelTable;
