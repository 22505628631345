import { RefObject } from "react";
import { react } from "uikit";

import { CompaniesAndTaxiServicesBase } from "../../../../../../../../../../../../../../components/common";

import DefaultTaxiServiceId from "./components/DefaultTaxiServiceId";
import RoleIds from "./components/RoleIds";

interface Context {
	defaultTaxiServiceIdRef: RefObject<DefaultTaxiServiceId.Ref | null>;
	roleIdsRef: RefObject<RoleIds.Ref | null>;
	companyBranchRef: RefObject<CompaniesAndTaxiServicesBase.Ref | null>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isRoleIdValid = !!this.context?.roleIdsRef.current?.validate();
		const isDefaultTaxiServiceIdRefValid =
			!!this.context?.defaultTaxiServiceIdRef.current?.validate();

		const isBranchesValid =
			!!this.context?.companyBranchRef.current?.validate();

		return (
			isRoleIdValid && isDefaultTaxiServiceIdRefValid && isBranchesValid
		);
	}
}
