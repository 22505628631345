import { AccessKey } from "../../../../../../../../../../../../../constants/access";

/**
 * These access keys are reserved and cannot be modified.
 * They represent critical parts of the system and will not be displayed
 * in the UI access tree for modification or selection.
 */

const reservedKeys = [[AccessKey.LOGIN], [AccessKey.MAIN]];

export default reservedKeys;
