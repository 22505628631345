/* eslint-disable no-shadow */

import React, {
	Dispatch,
	ReactNode,
	useCallback,
	useMemo,
	useRef,
} from "react";
import { UseTranslationResponse, useTranslation } from "react-i18next";
import { CheckBox, Column } from "uikit";
import { RowDataType, SortType } from "rsuite-table";
import { clone, isNull, isUndefined } from "lodash";
import moment from "moment";

import Language from "../../../../../../../../../../services/Language";
import Car from "../../../../../../../../../../services/Car";
import KeyBinder from "../../../../../../../../../../services/KeyBinder";
import Executor from "../../../../../../../../../../services/Executor";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import formatNumber from "../../../../../../../../../../utils/formatNumber";
import {
	useVisibleColumns,
	useWidths,
} from "../../../../../../../../../../hooks/useTableSettings";
import LightTable from "../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../components/TableSettings";
import ColumnSetupModal from "../../../../../../../../../../components/ColumnSetupModal";
import { StyledP } from "../../../../../../../../../../components/common";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

import { ColumnId, getColumns } from "./constants";

interface ColumnContext {
	language: Language;

	width: number;
	t: UseTranslationResponse<"translation", undefined>[0];
	onResize: (width?: number, columnId?: string) => void;
}

type CellContent = (rowData: RowDataType, rowIndex?: number) => React.ReactNode;

type Columns = Record<ColumnId, (context: ColumnContext) => ReactNode>;

const Columns: Columns = {
	fullName: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable sortable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str100",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="fullName" fullText>
				{
					((rowData: Executor.Model) =>
						`${rowData.person?.lastName ?? ""} ${
							rowData.person?.firstName ?? ""
						} ${
							rowData.person?.fatherName ?? ""
						}`.trim()) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	alias: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable sortable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str101",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="alias" fullText>
				{
					((rowData: Executor.Model) =>
						`${rowData.alias}`) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	parkNumber: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable sortable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str102",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="parkNumber" fullText>
				{
					((rowData: Executor.Model) =>
						`${rowData.cars
							?.filter((c) => !!c.parkNumber)
							.map((c) => c.parkNumber)
							.join(", ")}`) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	phones: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable sortable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str103",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="phones" fullText>
				{
					((rowData: Executor.Model) =>
						rowData.person?.phones
							?.filter((phone) => isNull(phone.deletedAt))
							.map((phone) => phone.number)
							.join(", ") ?? "") as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	balance: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable sortable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str104",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="balance" fullText>
				{
					((rowData: Executor.Model) => {
						const main =
							rowData.paymentAccounts?.find(
								(a) => a.type === "main",
							)?.amount ?? 0;

						const colorMainBalance = useMemo(() => {
							if (
								!rowData?.rate ||
								!rowData?.rate?.active ||
								!rowData?.rate?.amountOfMoneyToBlock?.active
							) {
								return "";
							}
							const { amountOfMoneyToBlock } = rowData.rate;

							if (amountOfMoneyToBlock.value >= main)
								return "red";

							return "";
						}, [rowData.rate, main]);

						return (
							<StyledP colors={colorMainBalance}>
								{formatNumber(main)}
							</StyledP>
						);
					}) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	bonusBalance: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable sortable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str105",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="bonusBalance" fullText>
				{
					((rowData: Executor.Model) =>
						formatNumber(
							rowData.paymentAccounts?.find(
								(a) => a.type === "bonus",
							)?.amount ?? 0,
						)) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	role: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable sortable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str106",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="role" fullText>
				{
					((rowData: Executor.Model) =>
						rowData.roles?.map((role) => role.name).join(", ") ??
						"") as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	company: ({ language, width, onResize, t }) => (
		<LightTable.Column width={width} resizable sortable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str107",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="company" fullText>
				{
					((item: Executor.Model) =>
						item.taxiService?.company?.name[
							language
						]) as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	taxiService: ({ language, width, onResize, t }) => (
		<LightTable.Column width={width} resizable sortable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str108",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="taxiService" fullText>
				{
					((item: Executor.Model) =>
						item.taxiService?.settlement[
							language
						]) as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	registeredAt: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable sortable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str109",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="registeredAt" fullText>
				{
					((item: Executor.Model) =>
						moment(item.createdAt).format(
							"DD.MM.YYYY",
						)) as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	responsibleDispatcher: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable sortable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str150",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="responsibleDispatcher" fullText>
				{
					((item: Executor.Model) =>
						[
							item.responsibleDispatcher?.person?.lastName ?? "",
							item.responsibleDispatcher?.person?.firstName ?? "",
							item.responsibleDispatcher?.person?.fatherName ??
								"",
						]
							.join(" ")
							.trim()) as unknown as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	notes: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable onResize={onResize}>
			<LightTable.HeaderCell>
				{t(
					"pages.mainPage.pages.accounts.tabs.executors.content.modelTable.str110",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell dataKey="notes" fullText>
				{((item: Car.Model) => item.notes) as unknown as CellContent}
			</LightTable.Cell>
		</LightTable.Column>
	),
};

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,
	sort,
	loading,
	data,
	onChangeSelected,
	onChangeSort,
	onEdit,
	onLoadMore,
}) => {
	const { t } = useTranslation();

	const columns = useMemo(() => getColumns(t), [t]);
	const defaultColumnIds = useMemo(() => columns.map((c) => c.id), [columns]);

	const language = useTypedSelector((state) => state.session.language);
	const canLoadMoreRef = useRef(true);
	const tableRef = useRef<LightTable.Ref | null>(null);

	const { columnIds, setColumnIds } = useVisibleColumns(
		"accounts.executors",
		"executor",
	);
	const { widths, setWidths } = useWidths("accounts.executors", "executor");

	const tableRowClassName = useCallback(
		(item: Executor.Model) => {
			if (selected.includes(item?.id ?? "")) return "selected";

			if (!item?.active) return "not-active";

			return "";
		},
		[selected],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore]);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				onChangeSelected(newSelected);
			} else
				onChangeSelected(
					selected.filter((selectedId) => selectedId !== id),
				);
		},
		[onChangeSelected, selected],
	);

	const tableOnRowClick = useCallback(
		(rowData) =>
			KeyBinder.isControlPressed
				? setItemSelection(rowData.id, !selected.includes(rowData.id))
				: onChangeSelected(
						selected.includes(rowData.id) ? [] : [rowData.id],
				  ),
		[onChangeSelected, selected, setItemSelection],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<LightTable
				ref={tableRef}
				fillHeight
				virtualized
				shouldUpdateScroll={false}
				headerHeight={44}
				rowHeight={44}
				sortColumn={sort.column}
				sortType={sort.type}
				loading={loading}
				data={data}
				rowClassName={tableRowClassName}
				onScroll={tableOnScroll}
				onRowClick={tableOnRowClick}
				onRowDoubleClick={onEdit}
				accessName={ARR_ACCESS_PART_KEY[TabKeys.EXECUTORS]}
				onSortColumn={(column, type) => onChangeSort({ column, type })}
			>
				<LightTable.Column width={36}>
					<LightTable.HeaderCell verticalAlign="middle">
						<CheckBox
							value={
								selected.length === data.length &&
								data.length !== 0
							}
							onChange={(value) =>
								onChangeSelected(
									value ? data.map((item) => item.id) : [],
								)
							}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell verticalAlign="middle">
						{(rowData) => (
							<CheckBox
								value={selected.includes(rowData.id)}
								onChange={(selected) =>
									KeyBinder.isControlPressed
										? setItemSelection(rowData.id, selected)
										: onChangeSelected(
												selected ? [rowData.id] : [],
										  )
								}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>

				{columnIds.map((columnId) =>
					Columns[columnId]({
						language,

						width: widths[columnId],

						onResize: (width, columnId) => {
							if (isUndefined(width) || isUndefined(columnId))
								return;

							setWidths({
								...widths,
								[columnId]: width,
							});
						},
						t,
					}),
				)}
			</LightTable>

			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns as any}
				onChange={setColumnIds as Dispatch<ColumnSetupModal.Value>}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		selected: number[];
		sort: Sort;
		loading: boolean;
		data: Executor.Model[];
		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;
		onEdit: Dispatch<number>;
		onLoadMore: () => void;
	}
}

export default ModelTable;
