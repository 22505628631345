import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";

import Language from "../../../../../../../../../../../../../../services/Language";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import { MultiSelectServiceWithModal } from "../../../../../../../../../../../../../../components/common/Modal";

const Services: React.FC<Services.Props> = ({
	value = [],
	onChange,
	disabled,
}) => {
	const { t } = useTranslation();
	return (
		<LabeledField
			label={
				t(
					"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.services.str0",
				) ?? ""
			}
		>
			<MultiSelectServiceWithModal
				disabled={disabled}
				value={value}
				onChange={onChange}
				titleText={
					"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.services.str0"
				}
				subscribeOptions={{
					availableForExecutor: true,
				}}
			/>
		</LabeledField>
	);
};

declare namespace Services {
	type Value = number[];

	interface Props {
		value: Value;

		disabled: boolean;

		language: Language;
		taxiServiceIds: number[];

		onChange: Dispatch<Value>;
	}
}

export default Services;
