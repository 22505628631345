import React, {
	PropsWithChildren,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { cloneDeep, isUndefined } from "lodash";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";
import styled from "styled-components";

import Order from "../../../../../../../../services/Order";
import PhoneBlacklist from "../../../../../../../../services/PhoneBlacklist";
import { useTypedSelector } from "../../../../../../../../redux/store";
import {
	Divider,
	StyledRow,
	StyledSpan,
	Button,
} from "../../../../../../../../components/common";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../access";
import { Mark } from "../../../../../components";
import { useOrderModalContext } from "../../../../../context";
import CloseOrderModal from "../../../CloseOrderModal";
import CopyOrderModal from "../../../CopyOrderModal";
import { CloseReason } from "../../../CloseOrderModal/components/Content";
import AddClientToBlacklistModal from "../../../AddClientToBlacklistModal";
import ClientCancelOrderModal from "../../../ClientCancelOrderModal";

import { SearchFilter } from "./components/SearchFilter";
import SendingSMS from "./components/SendingSMS";
import { ClientCall } from "./components/ClientCall";
import { DriverCall } from "./components/DriverCall";
import { ButtonFooter } from "./styled";

const BaseButton = styled(Button)`
	width: max-content;
	padding: 5px 20px;
	height: clamp(29px, 3.9vh, 32px);
`;

const Footer: React.FC<Footer.Props> = ({
	disabled = false,
	locked,
	save,
	cancel,
	close,
	onCloseTab,
}) => {
	const { t } = useTranslation();

	const {
		companyID,
		taxiServiceId,
		activeOrderId,
		phone: clientPhone,
		phones: clientAdditionalPhones,
		fullName,
		executorPhone,
		executorPhones,
		orderStatus,
		searchTypes,
		setSearchTypes,
		searchToggle,
		setSearchToggle,
		getAdditionalFields,
		showCustomerPhone,
		setOrdersTypeTabs,
		scheduledTime,
	} = useOrderModalContext();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const [closeOrderModalValue, setCloseOrderModalValue] =
		useState<CloseOrderModal.Value | null>(null);
	const [isClientCancelOrderModalOpen, setIsClientCancelOrderModalOpen] =
		useState(false);
	const [
		isAddClientToBlackListModalOpen,
		setIsAddClientToBlackListModalOpen,
	] = useState(false);
	const [isCloseOrderModalOpen, setIsCloseOrderModalOpen] = useState(false);
	const [isCloneOrderModalOpen, setIsCloneOrderModalOpen] = useState(false);

	const AccessBtnGroup = useMemo(() => {
		const ACCESS_SECTION = [
			AccessKey.ORDERS,
			AccessKey.ORDER_MODAL,
			AccessKey.FOOTER_PANEL,
		];

		const retval = {
			closeOrder: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.CLOSED_ORDER),
				personalRole,
			),
			copyOrder: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.COPY_ORDER),
				personalRole,
			),
			smsBtn: hasAccess(
				generateAccessName(...ACCESS_SECTION, AccessKey.SMS_BUTTON),
				personalRole,
			),
			callOrderClient: hasAccess(
				generateAccessName(
					...ACCESS_SECTION,
					AccessKey.CALL_ORDER_CLIENT,
				),
				personalRole,
			),
			callExecutorClient: hasAccess(
				generateAccessName(
					...ACCESS_SECTION,
					AccessKey.CALL_ORDER_EXECUTOR,
				),
				personalRole,
			),
			showClientNumberToExecutor: hasAccess(
				generateAccessName(
					...ACCESS_SECTION,
					AccessKey.SHOW_CLIENT_NUMBER_TO_EXECUTOR,
				),
				personalRole,
			),
		};

		return retval;
	}, [personalRole]);

	const clientPhones = useMemo(() => {
		const payload: string[] = [];
		if (clientPhone) payload.push(clientPhone);
		if (clientAdditionalPhones && clientAdditionalPhones.length) {
			payload.push(...clientAdditionalPhones);
		}
		return payload;
	}, [clientAdditionalPhones, clientPhone]);

	const openCloneOrderModal = useCallback(() => {
		setIsCloneOrderModalOpen(true);
	}, []);
	const openCloseOrderModal = useCallback(() => {
		setIsCloseOrderModalOpen(true);
	}, []);
	const closeCloneOrderModal = useCallback(() => {
		setIsCloneOrderModalOpen(false);
	}, []);

	const closeCloseOrderModal = useCallback(() => {
		setIsCloseOrderModalOpen(false);
	}, []);

	const cloneOrderModalOnSubmit = useCallback(
		async (value: CopyOrderModal.Value) => {
			if (activeOrderId) Order.copy(activeOrderId, value);
			setIsCloneOrderModalOpen(false);
		},
		[activeOrderId],
	);

	const closeAddClientToBlacklistModal = useCallback(() => {
		setIsAddClientToBlackListModalOpen(false);
		setCloseOrderModalValue(null);
	}, []);

	const blockClient = useCallback(
		(value: AddClientToBlacklistModal.Value) => {
			if (clientPhone && companyID) {
				PhoneBlacklist.store({
					level: value.level,
					description: value.comment ?? "",
					phone: clientPhone,
					firstName: fullName || " ",
					companyIds: [companyID],
				});
			}
		},
		[fullName, clientPhone, companyID],
	);

	const closeClientCancelOrderModal = useCallback(() => {
		setIsClientCancelOrderModalOpen(false);
		setCloseOrderModalValue(null);
	}, []);

	const submitClientCancelOrderModal = useCallback(
		(value: ClientCancelOrderModal.Value) => {
			setIsClientCancelOrderModalOpen(false);
			setCloseOrderModalValue(null);
			if (!activeOrderId) return;
			Order.close(activeOrderId, {
				status: closeOrderModalValue?.reason,
				subStatus: value.reason,
				comment: value.comment,
			});

			onCloseTab(activeOrderId);

			if (closeOrderModalValue?.addClientToBlackList) {
				blockClient({
					level: value.blockLevel,
					comment: value.blockComment,
				});
			}
		},
		[
			activeOrderId,
			blockClient,
			closeOrderModalValue?.addClientToBlackList,
			closeOrderModalValue?.reason,
			onCloseTab,
		],
	);

	const submitAddClientToBlacklistModal = useCallback(
		(value: AddClientToBlacklistModal.Value) => {
			setIsAddClientToBlackListModalOpen(false);
			setCloseOrderModalValue(null);
			if (activeOrderId) {
				Order.close(activeOrderId, {
					status: closeOrderModalValue?.reason,
				});
				blockClient(value);
				onCloseTab(activeOrderId);
			}
		},
		[activeOrderId, blockClient, closeOrderModalValue?.reason, onCloseTab],
	);

	const submitCloseOrderModal = useCallback(
		(value: CloseOrderModal.Value) => {
			setIsCloseOrderModalOpen(false);

			if (!activeOrderId) return;

			if (
				value.reason === CloseReason.ClientCanceled ||
				value.addClientToBlackList
			) {
				setCloseOrderModalValue(value);

				if (value.reason === CloseReason.ClientCanceled) {
					setIsClientCancelOrderModalOpen(true);
				} else {
					setIsAddClientToBlackListModalOpen(true);
				}
			} else {
				Order.close(activeOrderId, {
					status: value.reason,
				});
				onCloseTab(activeOrderId);
			}
		},
		[activeOrderId, onCloseTab],
	);

	const onShowClientNumberToExecutor = useCallback(() => {
		if (isUndefined(activeOrderId)) return;
		const orderAdditionalFields = getAdditionalFields();
		const additionalFields = cloneDeep(orderAdditionalFields);
		additionalFields.displaySettings =
			additionalFields.displaySettings ?? {};
		additionalFields.displaySettings.showCustomerPhone =
			!additionalFields.displaySettings.showCustomerPhone;
		Order.update({ id: activeOrderId, additionalFields });
	}, [activeOrderId, getAdditionalFields]);

	const eventKeydown = useCallback(
		(event: React.KeyboardEvent<HTMLButtonElement>) => {
			const keyEvent = event?.key;
			if (keyEvent === "Enter") {
				save();

				if (scheduledTime && scheduledTime > Date.now()) {
					setOrdersTypeTabs("preliminary");
				} else if (orderStatus === "creating") {
					setOrdersTypeTabs("live");
				}
				event?.stopPropagation();
				event?.preventDefault();
			}
			if (keyEvent === "Tab") {
				const nextElem = document.getElementById("main-phone-id");
				if (nextElem) nextElem.focus();
			}

			if (keyEvent === "ArrowRight") {
				const nextElem = document.getElementById("customer-phone-id");
				if (nextElem) nextElem.focus();
			}

			if (keyEvent === "ArrowLeft") {
				const nextElem = document.getElementById("driver-node-id");
				if (nextElem) nextElem.focus();
			}
		},
		[save, setOrdersTypeTabs, orderStatus, scheduledTime],
	);

	const onSave = useCallback(() => {
		save();

		if (scheduledTime && scheduledTime > Date.now()) {
			setOrdersTypeTabs("preliminary");
		} else if (orderStatus === "creating") {
			setOrdersTypeTabs("live");
		}
	}, [save, setOrdersTypeTabs, orderStatus, scheduledTime]);

	return (
		<StyledRow
			h="clamp(37px, 5.6vh, 50px)"
			p="0 18px"
			alignItems="center"
			w="100%"
			flex={{ wrap: "nowrap" }}
		>
			<StyledRow gap="5px" flex={{ wrap: "nowrap" }} alignItems="center">
				{isCloneOrderModalOpen && activeOrderId && (
					<CopyOrderModal
						onClose={closeCloneOrderModal}
						onSubmit={cloneOrderModalOnSubmit}
					/>
				)}

				{isCloseOrderModalOpen && activeOrderId && (
					<CloseOrderModal
						onClose={closeCloseOrderModal}
						onSubmit={submitCloseOrderModal}
					/>
				)}

				{isAddClientToBlackListModalOpen && activeOrderId && (
					<AddClientToBlacklistModal
						onClose={closeAddClientToBlacklistModal}
						onSubmit={submitAddClientToBlacklistModal}
					/>
				)}

				{isClientCancelOrderModalOpen && activeOrderId && (
					<ClientCancelOrderModal
						addClientToBlackList={
							closeOrderModalValue?.addClientToBlackList ?? false
						}
						onClose={closeClientCancelOrderModal}
						onSubmit={submitClientCancelOrderModal}
					/>
				)}

				{AccessBtnGroup.closeOrder && (
					<ButtonFooter
						disabled={
							orderStatus === "creating" ||
							orderStatus === "closed" ||
							disabled
						}
						variant="secondary"
						settingsKeyId="orderModalCloseOrder"
						title={
							t(
								"pages.mainPage.pages.orders.orderModal.footer.str0",
							) ?? ""
						}
						onClick={openCloseOrderModal}
					>
						<Icon id="orders-close" size={24} />
					</ButtonFooter>
				)}

				{AccessBtnGroup.copyOrder && (
					<ButtonFooter
						disabled={
							orderStatus === "creating" || disabled
								? orderStatus !== "closed"
								: disabled
						}
						variant="secondary"
						settingsKeyId="orderModalCopyOrder"
						title={
							t(
								"pages.mainPage.pages.orders.orderModal.footer.str1",
							) ?? ""
						}
						onClick={openCloneOrderModal}
					>
						<Icon id="orders-copy" size={24} />
					</ButtonFooter>
				)}
				<Divider h="24px" />
				{AccessBtnGroup.smsBtn && (
					<SendingSMS
						disabled={
							disabled ? orderStatus !== "closed" : disabled
						}
						mainPhone={clientPhone}
						phones={clientPhones}
						taxiServiceId={taxiServiceId}
					/>
				)}
				{AccessBtnGroup.callOrderClient && (
					<ClientCall
						disabled={
							disabled ? orderStatus !== "closed" : disabled
						}
						mainPhone={clientPhone}
						phones={clientPhones}
					/>
				)}
				{AccessBtnGroup.callExecutorClient && (
					<DriverCall
						disabled={
							disabled ? orderStatus !== "closed" : disabled
						}
						mainPhone={executorPhone}
						phones={executorPhones}
					/>
				)}
				{AccessBtnGroup.showClientNumberToExecutor && (
					<ButtonFooter
						disabled={orderStatus === "creating" || disabled}
						active={showCustomerPhone}
						variant="secondary"
						settingsKeyId="orderModalShowPhoneToExecutor"
						title={
							t(
								"pages.mainPage.pages.orders.orderModal.footer.str2",
							) ?? ""
						}
						onClick={onShowClientNumberToExecutor}
					>
						<Icon
							id="orders-show-client-number-to-executor"
							size={20}
						/>
					</ButtonFooter>
				)}

				<Divider h="24px" />

				<SearchFilter
					disabled={disabled}
					toggles={searchToggle}
					onChangeToggle={setSearchToggle}
					setSearchType={setSearchTypes}
					activeSearchTypes={searchTypes}
				/>
				<Divider h="24px" />
				<BaseButton
					disabled={disabled}
					title={t([`close_all`, "Close all tabs"]) ?? ""}
					variant="secondary"
					onClick={close}
				>
					{t([`close_all`, "Close all tabs"]) ?? ""}
				</BaseButton>
			</StyledRow>

			<StyledRow
				flex={{ wrap: "nowrap", grow: 1 }}
				gap="15px"
				justify="start"
				alignItems="center"
				p="0 1rem"
			>
				{locked && (
					<>
						<Divider h="18px" m="0 10px" />
						<StyledSpan>
							{t([
								"order_editing",
								`The order is already edited by the user -`,
							])}
							<Mark>{`${locked?.name}`}</Mark>
						</StyledSpan>
					</>
				)}
			</StyledRow>

			<StyledRow gap="13px" flex={{ wrap: "nowrap" }}>
				<BaseButton
					title={t([`cancel`, "Cancel"]) ?? ""}
					settingsKeyId="orderModalCancelOrder"
					variant="secondary"
					onClick={cancel}
				>
					{t([`cancel`, "Cancel"]) ?? ""}
				</BaseButton>

				<BaseButton
					onKeyDown={eventKeydown}
					id="order-save-id"
					onClick={onSave}
					disabled={disabled}
					tabIndex={11}
					title={t([`save`, "Save"]) ?? ""}
					settingsKeyId="orderModalEditOrder"
					variant="primary"
					css={`
						:focus {
							border-radius: 6px;
							border: 1px solid #21333f;
						}
					`}
				>
					{t([`save`, "Save"]) ?? ""}
				</BaseButton>
			</StyledRow>
		</StyledRow>
	);
};

declare namespace Footer {
	interface Props extends PropsWithChildren {
		locked?: any;
		disabled?: boolean;
		save: () => void;
		cancel: () => void;
		close: () => void;
		onCloseTab: (orderId: number) => void;
	}
}

export const FooterMemo = memo(Footer);
export default Footer;
