import React, { useMemo, useEffect } from "react";
import { Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import { Dispatcher2 } from "../../../../services";
import { useTypedSelector, useTypedDispatch } from "../../../../redux/store";
import account from "../../../../redux/reducers/account";
import { useActiveTab, useModelSubscribe } from "../../../../hooks";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import { ExecutorTeamsProvider } from "../context";

import {
	Car,
	Users,
	Roles,
	CarParks,
	WorkShifts,
	Executors,
	ExecutorTeams,
} from "./tabs";
import { TabKeys, TabAccessNames } from "./constants/access";

const Accounts: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const lang = useTypedSelector((state) => state.session.language);
	const user = useTypedSelector((state) => state.account.user);
	const dispatcher = useModelSubscribe({ lang }, Dispatcher2);

	const dispatcherServices = useMemo(
		() => dispatcher.models?.find((item) => item.id === user?.id),
		[dispatcher.models, user?.id],
	);

	useEffect(() => {
		if (dispatcherServices && user?.id) {
			dispatch(account.actions.setUser(dispatcherServices));
		}
	}, [dispatcherServices, dispatch, user?.id]);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.EXECUTORS,
					label: t("mainPage.accounts.executors.title"),
					value: {
						render: () => <Executors />,
					},
					accessName: TabAccessNames[TabKeys.EXECUTORS],
				},
				{
					key: TabKeys.TRANSPORT,
					label: t("mainPage.accounts.transport.title"),
					value: {
						render: () => <Car />,
					},
					accessName: TabAccessNames[TabKeys.TRANSPORT],
				},
				{
					key: TabKeys.USERS,
					label: t("mainPage.accounts.users.title"),
					value: {
						render: () => <Users />,
					},
					accessName: TabAccessNames[TabKeys.USERS],
				},
				{
					key: TabKeys.ROLES,
					label: t("mainPage.accounts.roles.title"),
					value: {
						render: () => <Roles />,
					},
					accessName: TabAccessNames[TabKeys.ROLES],
				},
				{
					key: TabKeys.CAR_PARKS,
					label: t("mainPage.accounts.carParks.title"),
					value: {
						render: () => <CarParks />,
					},
					accessName: TabAccessNames[TabKeys.CAR_PARKS],
				},
				{
					key: TabKeys.SCHEDULES,
					label: t("mainPage.accounts.schedules.title"),
					value: {
						render: () => <WorkShifts />,
					},
					accessName: TabAccessNames[TabKeys.SCHEDULES],
				},
				{
					key: TabKeys.EXECUTOR_TEAMS,
					label: t("mainPage.accounts.executorTeams.title"),
					value: {
						render: () => (
							<ExecutorTeamsProvider>
								<ExecutorTeams />
							</ExecutorTeamsProvider>
						),
					},
					accessName: TabAccessNames[TabKeys.EXECUTOR_TEAMS],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<Tabs
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default Accounts;
