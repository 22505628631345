import makeTable from "./makeTable";

const fuelDeductionOrdersReport = makeTable({
	allIds: [
		"orderNumber",
		"creationDate",
		"counterparty",
		"erpou",
		"executionDate",
		"from",
		"to",
		"taskFromToKm",
		"distanceSubmissionKm",
		"returnDistanceKm",
		"totalDistanceKm",
		"totalTaskDistanceKm",
		"note",
		"orderNote",
		"idleTimeMin",
		"priceUah",
		"operator",
		"garageNumber",
		"callSign",
		"driverFullName",
	],
	i18tPath: (id) => `tableColumns.accounts.roles.${id}`,
	defaultIds: [
		"orderNumber",
		"creationDate",
		"counterparty",
		"erpou",
		"executionDate",
		"from",
		"to",
		"taskFromToKm",
		"distanceSubmissionKm",
		"returnDistanceKm",
		"totalDistanceKm",
		"totalTaskDistanceKm",
		"note",
		"orderNote",
		"idleTimeMin",
		"priceUah",
		"operator",
		"garageNumber",
		"callSign",
		"driverFullName",
	],
	initialSortMethod: {
		column: "creationDate",
		type: "asc",
	},
	initialWidths: {
		orderNumber: 150,
		creationDate: 120,
		counterparty: 150,
		erpou: 120,
		executionDate: 120,
		from: 120,
		to: 150,
		taskFromToKm: 150,
		distanceSubmissionKm: 150,
		returnDistanceKm: 150,
		totalDistanceKm: 150,
		totalTaskDistanceKm: 150,
		note: 120,
		orderNote: 150,
		idleTimeMin: 120,
		priceUah: 120,
		operator: 120,
		garageNumber: 150,
		callSign: 120,
		driverFullName: 150,
	},
	initialResizable: {
		orderNumber: true,
		creationDate: true,
		counterparty: true,
		erpou: true,
		executionDate: true,
		from: true,
		to: true,
		taskFromToKm: true,
		distanceSubmissionKm: true,
		returnDistanceKm: true,
		totalDistanceKm: true,
		totalTaskDistanceKm: true,
		note: true,
		orderNote: true,
		idleTimeMin: true,
		priceUah: true,
		operator: true,
		garageNumber: true,
		callSign: true,
		driverFullName: true,
	},
	initialSortable: {
		orderNumber: true,
		creationDate: true,
		counterparty: true,
		erpou: true,
		executionDate: true,
		from: true,
		to: true,
		taskFromToKm: true,
		distanceSubmissionKm: true,
		returnDistanceKm: true,
		totalDistanceKm: true,
		totalTaskDistanceKm: true,
		note: true,
		orderNote: true,
		idleTimeMin: true,
		priceUah: true,
		operator: true,
		garageNumber: true,
		callSign: true,
		driverFullName: true,
	},
});

export default fuelDeductionOrdersReport;
