import React, { memo, useCallback, useMemo } from "react";
import { Icon } from "uikit";

import { useOrderModalContext } from "../../../../../context";
import { useKeyBindSettings } from "../../../../../../../../hooks/useKeyBindSettings";
import { OrderTimeSelectMemo } from "../../../../../../../../components/Orders";
import {
	StyledRow,
	StyledWrapIcon,
} from "../../../../../../../../components/common";
import { OrderFormProps } from "../../types/FormProps";

const ScheduledTime: React.FC<ScheduledTime.Props> = ({
	tab,
	form,
	disabled,
}) => {
	const { nextElementById } = useOrderModalContext();
	const displayModal = useMemo(
		() => tab.state?.modals?.time,
		[tab.state?.modals?.time],
	);

	const time = useMemo(() => {
		const scheduledTime = tab.form?.scheduledTime;
		if (scheduledTime) return new Date(scheduledTime);
		return null;
	}, [tab.form.scheduledTime]);

	const onChangeDisplayModal = useCallback(
		(display?: boolean) => {
			tab.setState((prev) => ({
				...prev,
				modals: { ...prev.modals, time: !!display },
			}));
		},
		[tab],
	);

	const styles = useMemo(
		() => ({
			icon: {
				w: "16px",
				h: "16px",
				colors: disabled ? "#B5C0C7" : "#5e6b73",
				cursor: disabled ? "not-allowed" : "pointer",
			},
		}),
		[disabled],
	);

	const orderTimeSelectOnChange = useCallback(
		(value) => {
			const scheduledTime = value ? Number(value) : undefined;

			form.setValue("scheduledTime", scheduledTime);
			if (!scheduledTime) {
				const time = new Date();
				time.setSeconds(0, 0);
				form.setValue("orderDate", time);
			}
			form.setValue("orderSave", true);

			nextElementById("customer-phone-id");
		},
		[form, nextElementById],
	);

	useKeyBindSettings("orderModalScheduledModal", (event) => {
		if (!disabled) {
			onChangeDisplayModal(true);
		}
		event.preventDefault();
		event.stopPropagation();
	});

	return (
		<StyledRow alignItems="center" bgC="#F6F6F6">
			<StyledWrapIcon {...styles.icon}>
				<Icon id="order-modal-time" size={16} />
			</StyledWrapIcon>
			<OrderTimeSelectMemo
				disabled={disabled}
				value={time}
				onChange={orderTimeSelectOnChange}
				displayModal={displayModal}
				onChangeDisplayModal={onChangeDisplayModal}
			/>
		</StyledRow>
	);
};

declare namespace ScheduledTime {
	interface Props extends OrderFormProps {}
}
export const ScheduledTimeMemo = memo(ScheduledTime);
export default ScheduledTime;
