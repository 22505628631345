import React from "react";
import styled from "styled-components";
import { theme } from "uikit"; // Убедитесь, что у вас правильный путь к теме

const SeparatorContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const DividerLine = styled.div<{ color?: string }>`
	flex-grow: 1;
	border-bottom: 1px solid
		${(props) => props.color || theme.colors.button_secondary};
`;

const Text = styled.span`
	padding: 0 8px;
	color: ${theme.colors.text};
	font-size: 12px;
	font-weight: bold;
`;

interface SeparatorProps {
	text?: string;
	color?: string;
}

const Separator: React.FC<SeparatorProps> = ({ text, color }) => (
	<SeparatorContainer>
		<DividerLine color={color} />
		{text && <Text>{text}</Text>}
		<DividerLine color={color} />
	</SeparatorContainer>
);

export default Separator;
