import React, { Dispatch, memo, useCallback, useEffect, useState } from "react";
import { CheckBox } from "uikit";
import { useTranslation } from "react-i18next";

import { BaseRateAdjustmentType } from "../../../../../../../../services/Tariff/enums";
import useObjectEditor from "../../../../../../../../hooks/useObjectEditor";
import DefaultPageHeader from "../../../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../../../components/DeleteModal";
import { StyledColumn } from "../../../../../../../../components/common";
import tPath from "../../../../constants/tPath";

import defaultValue from "./constants/defaultValue";
import Root from "./components/Root";
import Content from "./components/Content";
import Modal from "./components/Modal";

const PassengersSurcharge: React.FC<PassengersSurcharge.Props> = memo(
	(props) => {
		const { disabled, root, value, onChange } = props;
		const path = `${tPath}.mainModal.tabs.main.surcharges`;

		const { t } = useTranslation();

		const editor = useObjectEditor(value, onChange);

		const active = editor.useGetter("active");
		const setActive = editor.useSetter("active");

		const intervals = editor.useGetter("intervals");
		const setIntervals = editor.useSetter("intervals");

		const [selected, setSelected] = useState<number[]>([]);

		const [showModal, setShowModal] = useState(false);
		const [showDeleteModal, setShowDeleteModal] = useState(false);

		const [editingItem, setEditingItem] = useState(defaultValue);
		const [indexEditingItem, setIndexEditingItem] = useState<number | null>(
			null,
		);

		useEffect(() => {
			setEditingItem({
				...defaultValue,
				count: intervals.reduce(
					(acc, curr) => (curr.count === acc ? acc + 1 : acc),
					1,
				),
			});
		}, [intervals, indexEditingItem]);

		const edit = useCallback(
			(index: number) => {
				const item = intervals[index];

				setEditingItem(item);
				setShowModal(true);
			},
			[intervals],
		);

		const addHandler = useCallback(() => {
			setShowModal(true);
		}, []);

		const editHeaderHandler = useCallback(() => {
			edit(selected[0]);
			setIndexEditingItem(selected[0]);
			setSelected([]);
		}, [edit, selected]);

		const editContentHandler = useCallback(
			(index: number) => {
				setIndexEditingItem(index);
				edit(index);
				setSelected([]);
			},
			[edit],
		);

		const cancelHandler = useCallback(() => {
			setShowModal(false);
			setIndexEditingItem(null);
		}, []);

		const saveHandler = useCallback(
			(interval: PassengersSurcharge.Interval) => {
				if (indexEditingItem != null) {
					const updatedintervals = [...intervals];
					updatedintervals[indexEditingItem] = interval;
					updatedintervals.sort((a, b) => a.count - b.count);
					setIntervals(updatedintervals);
					setIndexEditingItem(null);
				} else {
					setIntervals(
						[...intervals, interval].sort(
							(a, b) => a.count - b.count,
						),
					);
				}

				setShowModal(false);
			},
			[indexEditingItem, intervals, setIntervals],
		);

		const deleteHandler = useCallback(() => {
			const newintervals = intervals.filter(
				(_, i) => !selected.includes(i as never),
			);
			setIntervals(newintervals);
			setSelected([]);
			setShowDeleteModal(false);
		}, [intervals, selected, setIntervals]);

		return (
			<StyledColumn gap="10px" m="0 5px 0 0">
				<CheckBox
					disabled={disabled}
					value={active}
					label={`${t(`${path}.passengersSurcharge`)}:` || ""}
					onChange={setActive}
				/>
				<Root gaps="12px*" sizes="auto 1fr">
					<DefaultPageHeader
						canEdit={selected.length === 1}
						canDelete={!!selected.length}
						onAdd={addHandler}
						onEdit={editHeaderHandler}
						onDelete={() => {
							setShowDeleteModal(true);
						}}
					/>
					<Content
						intervals={intervals}
						selected={selected}
						setSelected={setSelected}
						onEdit={editContentHandler}
					/>
					{showModal && (
						<Modal
							root={root}
							headerTitle={t(`${path}.modal.title`)}
							value={editingItem}
							onCancel={cancelHandler}
							onSave={saveHandler}
							intervals={intervals}
						/>
					)}
					{showDeleteModal && (
						<DeleteModal
							label={
								(selected.length === 1
									? t(`${path}.deleteModal.title`)
									: t(`${path}.deleteModal.title2`)) || ""
							}
							onCancel={() => {
								setShowDeleteModal(false);
							}}
							onConfirm={deleteHandler}
						/>
					)}
				</Root>
			</StyledColumn>
		);
	},
);

declare namespace PassengersSurcharge {
	interface Props {
		root: boolean;
		disabled?: boolean;
		value: Value;
		onChange: Dispatch<Value>;
	}

	interface Value {
		active: boolean;
		intervals: Interval[];
	}

	interface Interval {
		active: boolean;
		count: number;
		type: BaseRateAdjustmentType | null;
		value: number;
	}
}

export default PassengersSurcharge;
