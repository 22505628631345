import React, {
	Dispatch,
	ReactNode,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Column } from "uikit";
import { RowDataType, SortType } from "rsuite-table";
import { clone } from "lodash";

import Language from "../../../../../../../../../../services/Language";
import KeyBinder from "../../../../../../../../../../services/KeyBinder";
import ExecutorRate from "../../../../../../../../../../services/ExecutorRate";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import { getColumnsModeTwo } from "../../../../../../../../../../utils";
import LightTable from "../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../components/TableSettings";
import ColumnSetupModal from "../../../../../../../../../../components/ColumnSetupModal";
import {
	TranslationText,
	CompaniesByIds,
	TaxiServicesByIds,
	StyledP,
} from "../../../../../../../../../../components/common";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

import TableBase from "./components/TableBase";

const TABLE_HEADER_HEIGHT = 44;
const TABLE_ROW_HEIGHT = 44;

interface ColumnContext {
	language: Language;
}

type CellContent = (rowData: RowDataType, rowIndex?: number) => React.ReactNode;

type Columns = Record<
	"name" | "companies" | "taxiServices",
	(context: ColumnContext) => ReactNode
>;

const Columns: Columns = {
	name: () => (
		<LightTable.Column width={300} resizable sortable fullText>
			<LightTable.HeaderCell>
				<TranslationText
					text={[
						"pages.settings.pages.finances.tabs.executorTariffPlans2.content.modelTable.str200",
						"Name",
					]}
				/>
			</LightTable.HeaderCell>
			<LightTable.Cell fullText>
				{
					((rowData: ExecutorRate.Model) => (
						<StyledP>{rowData.name || ""}</StyledP>
					)) as CellContent
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	companies: ({ language }) => (
		<LightTable.Column width={190} resizable sortable fullText>
			<LightTable.HeaderCell>
				<TranslationText
					text={[
						"pages.settings.pages.finances.tabs.executorTariffPlans2.content.modelTable.str201",
						"Companies",
					]}
				/>
			</LightTable.HeaderCell>
			<LightTable.Cell fullText>
				{(rowData) => {
					const model = rowData as ExecutorRate.Model;

					return (
						<CompaniesByIds
							taxiServiceIds={model?.taxiServiceIds || []}
							lang={language}
						/>
					);
				}}
			</LightTable.Cell>
		</LightTable.Column>
	),

	taxiServices: ({ language }) => (
		<LightTable.Column width={360} resizable sortable fullText>
			<LightTable.HeaderCell>
				<TranslationText
					text={[
						"pages.settings.pages.finances.tabs.executorTariffPlans2.content.modelTable.str202",
						"Branches",
					]}
				/>
			</LightTable.HeaderCell>
			<LightTable.Cell fullText>
				{(rowData) => {
					const model = rowData as ExecutorRate.Model;
					return (
						<TaxiServicesByIds
							taxiServiceIds={model?.taxiServiceIds || []}
							lang={language}
						/>
					);
				}}
			</LightTable.Cell>
		</LightTable.Column>
	),
};

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,
	sort,
	loading,
	data,

	onChangeSelected,
	onChangeSort,

	onEdit,
	onLoadMore,
}) => {
	const { t } = useTranslation();

	const defaultColumnIds: Parameters<typeof getColumnsModeTwo>[1] = useMemo(
		() => ["name", "companies", "taxiServices"],
		[],
	);

	const columns = useMemo(
		() => getColumnsModeTwo(t, defaultColumnIds),
		[t, defaultColumnIds],
	);

	const language = useTypedSelector((state) => state.session.language);
	const canLoadMoreRef = useRef(true);
	const tableRef = useRef<LightTable.Ref | null>(null);

	const tableRowClassName = useCallback(
		(item: ExecutorRate.Model) => {
			if (selected.includes(item?.id ?? "")) return "selected";

			if (!item?.active) return "not-active";

			return "";
		},
		[selected],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore]);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (KeyBinder.isControlPressed) {
				if (value) {
					const newSelected = clone(selected);

					newSelected.push(id);

					onChangeSelected(newSelected);
				} else
					onChangeSelected(
						selected.filter((selectedId) => selectedId !== id),
					);
			} else if (value || selected.length > 1) {
				onChangeSelected([id]);
			} else {
				onChangeSelected([]);
			}
		},
		[onChangeSelected, selected],
	);

	const tableOnRowClick = useCallback(
		(rowData) =>
			setItemSelection(rowData.id, !selected.includes(rowData.id)),
		[selected, setItemSelection],
	);

	const [columnIds, setColumnIds] = useState(defaultColumnIds);

	const columnContext = useMemo<ColumnContext>(
		() => ({ language }),
		[language],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<TableBase
				ref={tableRef}
				fillHeight
				virtualized
				headerHeight={TABLE_HEADER_HEIGHT}
				rowHeight={TABLE_ROW_HEIGHT}
				shouldUpdateScroll={false}
				sortColumn={sort.column}
				sortType={sort.type}
				loading={loading}
				data={data}
				rowClassName={tableRowClassName}
				onScroll={tableOnScroll}
				onRowClick={tableOnRowClick}
				onRowDoubleClick={onEdit}
				accessName={
					ARR_ACCESS_PART_KEY[TabKeys.EXECUTOR_TARIFF_PLANS_2]
				}
				onSortColumn={(column, type) => onChangeSort({ column, type })}
			>
				<LightTable.Column width={36}>
					<LightTable.HeaderCell verticalAlign="middle" hint={false}>
						<CheckBox
							value={
								selected.length === data.length &&
								data.length !== 0
							}
							onChange={(value) =>
								onChangeSelected(
									value ? data.map((item) => item.id) : [],
								)
							}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell verticalAlign="middle">
						{(rowData) => (
							<CheckBox
								value={selected.includes(rowData.id)}
								onChange={(selected) =>
									setItemSelection(rowData.id, selected)
								}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>

				{columnIds.map((columnId) => Columns[columnId](columnContext))}
			</TableBase>

			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns as any}
				onChange={setColumnIds as Dispatch<ColumnSetupModal.Value>}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		selected: number[];
		sort: Sort;
		loading: boolean;
		data: { id: number; taxiServiceIds: number[] }[];
		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;
		onEdit: Dispatch<number>;
		onLoadMore: () => void;
	}
}

export default ModelTable;
