import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { StyledRow } from "../../../../../../../../../components/common";
import { OrderFormProps } from "../../../types/FormProps";
import { Primary, Gray } from "../../styled";

const OrderDate: React.FC<OrderDate.Props> = ({ tab }) => {
	const { t } = useTranslation();

	const orderDate = useMemo(() => tab.form?.orderDate, [tab.form?.orderDate]);
	const filledAt = useMemo(() => tab.form?.filledAt, [tab.form?.filledAt]);
	const filled = useMemo(() => tab.form?.filled, [tab.form?.filled]);

	const status = useMemo(() => tab.form?.status, [tab.form?.status]);

	const created = useMemo(() => {
		if (orderDate && status && status !== "creating") {
			if (filled && filledAt) {
				const time = moment(filledAt).format("HH:mm:ss");
				const date = moment(filledAt).format("DD.MM.YYYY");
				return { time, date };
			}

			const time = moment(orderDate).format("HH:mm:ss");
			const date = moment(orderDate).format("DD.MM.YYYY");
			return { time, date };
		}
		return { time: "", date: "" };
	}, [filled, filledAt, orderDate, status]);

	return (
		<StyledRow gap="0 6px">
			<Primary>
				{`${t([`orderPage.order_form.created`, "Created"])}:`}
			</Primary>
			<Gray>{created.date}</Gray>
			<Primary>{created.time}</Primary>
		</StyledRow>
	);
};

declare namespace OrderDate {
	interface Props extends OrderFormProps {}
}
export const OrderDateMemo = memo(OrderDate);
export default OrderDate;
