import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Row, CheckBox, TimeInput } from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { DateFns } from "../../../../../../../../../../../../../../../../../../../../utils/DateFns";

const ValidityTime: React.FC<ValidityTime.Props> = memo(
	({ value, onChange }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);
		const dateFns = useMemo(() => new DateFns(), []);
		const utc = useMemo(
			() => Math.abs(new Date().getTimezoneOffset() * 60_000),
			[],
		);

		const active = valueEditor.useGetter("active");
		const setActive = valueEditor.useSetter("active");

		const timeFrom = valueEditor.useGetter("from");
		const setTimeFrom = valueEditor.useSetter("from");
		const handleFromChange = useCallback(
			(date) => {
				const from = dateFns.millisecondsFromDate(date, { utc: false });

				setTimeFrom(from - utc);
			},
			[dateFns, setTimeFrom, utc],
		);

		const timeTo = valueEditor.useGetter("to");
		const setTimeTo = valueEditor.useSetter("to");
		const handleToChange = useCallback(
			(date) => {
				const to = dateFns.millisecondsFromDate(date, { utc: false });

				setTimeTo(to - utc);
			},
			[dateFns, setTimeTo, utc],
		);

		const fromToTime = useMemo(() => {
			const timeFromNumber = new Date(timeFrom + utc).getTime();
			const timeToNumber = new Date(timeTo + utc).getTime();

			return {
				from: dateFns.millisecondsToDate(timeFromNumber, {
					utc: false,
				}),
				to: dateFns.millisecondsToDate(timeToNumber, { utc: false }),
			};
		}, [dateFns, timeFrom, timeTo, utc]);

		return (
			<Row gaps="10px*" align="center">
				<CheckBox
					label={
						t(
							"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.validityTime.str0",
						) ?? ""
					}
					value={active}
					onChange={setActive}
				/>
				<TimeInput
					value={fromToTime.from}
					onChange={handleFromChange}
				/>
				<span>
					{t(
						"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.validityTime.str100",
					) ?? ""}
				</span>
				<TimeInput value={fromToTime.to} onChange={handleToChange} />
			</Row>
		);
	},
);

declare namespace ValidityTime {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	interface Value {
		active: boolean;
		from: number;
		to: number;
	}
}

export default ValidityTime;
