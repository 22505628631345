import React, {
	PropsWithChildren,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { cloneDeep } from "lodash";
import { Option } from "uikit";
import { useTranslation } from "react-i18next";
import { LatLngLiteral } from "leaflet";

import Map from "../../../../../../services/Map";
import useMapSearch from "../../../../../../hooks/useMapSearch";
import { MapSearch } from "../../../../../Orders";
import { Popup } from "../../../../../common";
import PointOrderModalTableBase from "../..";
import { StyledInput, StyledPopup } from "../styled";
import { OverlayStreet } from "../Overlays";
import {
	HandleEventsProps,
	useHandleEvents,
	ValueAccessors,
} from "../../hooks";
import cityToString from "../utils/cityToString";

const StreetBase: React.FC<StreetBase.Props> = ({
	rowData,
	rowIndex,
	dataKey,
	colIndex,
	styles,
	offset,
	customerId,
	setData,
	settlement,
	settlementCoordinates,
	street,
	addStreet,
	height,
	handleEventsOptions,
	containerId,
	searchTypes = [],
	taxiServiceId,
	addSettlement,
	addSettlementCoordinates,
}) => {
	const {
		language,
		inputValue,
		query,
		open,
		onFocusToInput,
		onChangeInputValue,
		onEnterToInput,
		onExistFromInput,
		onClickToInput,
		setColIndex,
		setDataKey,
		setInputId,
		setRowId,
		setRowIndex,
		reset,
		onBlurCaptureToInput,
		setInputValue,
		allowType,
		setAllowType,
		setOpen,
		debounceClose,
		nextFocus,
	} = useHandleEvents(handleEventsOptions);
	const { t } = useTranslation();
	const pointOnMapFrom = useMemo(() => t([`no_address_point_from`]), [t]);
	const pointOnMapTo = useMemo(() => t([`no_address_point_to`]), [t]);

	const [activeId, setActiveOption] = useState<number>(-1);
	const [result, setResult] = useState<MapSearch.Result>({
		loading: true,
	});

	useMemo(() => {
		if (!rowData.raw.type) {
			reset();
			setResult({ loading: true });
		}
	}, [reset, rowData.raw]);

	useMemo(() => {
		if (query !== null && allowType === "reset") {
			setResult({ loading: true });
			addStreet(undefined, rowData.raw.id);
			setAllowType(null);

			return;
		}

		if (
			settlement?.settlement &&
			settlement?.settlement !== rowData.raw.settlement
		) {
			rowData.raw.saveType = "street";
			setInputValue(null);
			setResult({ loading: true });
			addStreet(undefined, rowData.raw.id);
			setAllowType(null);
			return;
		}
		if (!street && !allowType && !rowData.raw.saveType && !result.loading) {
			setResult({ loading: true });
		}
		if (
			rowData.raw.saveType === "house" ||
			rowData.raw.saveType === "entrance"
		) {
			if (!allowType) {
				rowData.raw.saveType = "street";
				setInputValue(null);
			}
		}
	}, [
		addStreet,
		allowType,
		query,
		result.loading,
		rowData.raw,
		setAllowType,
		setInputValue,
		settlement,
		street,
	]);

	const querySearch = useMemo(() => {
		if (allowType === "query" && query && query?.length > 1) {
			setAllowType("reset");
		}
		return query || "";
	}, [allowType, query, setAllowType]);

	useEffect(() => {
		setColIndex(`${colIndex}`);
		setDataKey(dataKey);
		setInputId(`${rowData.raw.id + dataKey}`);
		setRowId(rowData.raw.id);
		setRowIndex(`${rowIndex}`);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const streetShow = useCallback(
		(data: {
			streetType?: string;
			street?: string;
			customStreet?: string;
			house?: string;
			name?: string;
			title?: string;
			type: PointOrderModalTableBase.OrderModal["raw"]["type"];
		}) => {
			const typeStreet = data.streetType || "";
			const street = data.street || data.customStreet || "";
			const showStreet = `${street} ${typeStreet}`.trim();
			if (
				(data.name && data.type !== "street") ||
				(data.title && data.type !== "street")
			) {
				const name = data.name || data.title || "";
				return `${name} ${showStreet ? `(${showStreet})` : ""}`.trim();
			}
			return showStreet;
		},
		[],
	);

	const point = useMemo(() => settlementCoordinates, [settlementCoordinates]);

	const updatedData = useCallback((params: Map.Search.Object) => {
		if (
			settlement?.settlement &&
			settlement?.settlement !== params.settlement
		) {
			addSettlement(params, rowData.raw.id);
			addSettlementCoordinates(params.coordinates, rowData.raw.id);
		}
		nextFocus();
		addStreet(params, rowData.raw.id);

		const city = cityToString(params);
		const street = streetShow(params);
		const newData = cloneDeep(rowData);

		const optionsType: Map.Search.Type[] = [
			"favorite",
			"localObject",
			"object",
			"localObject",
		];
		// base
		newData.city = city;
		newData.street = street;
		newData.name = params.name || "";
		newData.customHouse = params.customHouse || "";
		newData.customStreet = params.customStreet || "";
		newData.title = params.title || "";

		newData.save = true;
		newData.saveType = "street";
		newData.raw.saveType = "street";
		newData.raw.save = !!rowData.raw.type;

		if (!params.houses?.length) newData.raw.save = true;
		if (params.houses?.length) newData.raw.save = false;
		if (optionsType.includes(params.type)) newData.raw.save = false;
		if (params.type === "localObject") newData.raw.save = true;
		newData.type = params.type;
		newData.coordinates = params.coordinates;
		newData.house = params.house || params.number || "";
		// raw
		newData.raw.type = params.type;
		newData.raw.coordinates = params.coordinates;
		newData.raw.country = params.country;
		newData.raw.countryCode = params.countryCode;

		newData.raw.sector = params.sector || newData.sector || undefined;

		newData.raw.region = params.region || "";
		newData.raw.district = params.district || "";

		newData.raw.settlement = params.settlement || "";
		newData.raw.settlementType = params.settlementType || "";

		newData.raw.name = params.name || params.title || "";
		newData.raw.customHouse = params.customHouse || "";
		newData.raw.customStreet = params.customStreet || "";

		newData.raw.street = params.street || params.customStreet || "";
		newData.raw.streetType = params.streetType || "";
		newData.raw.house =
			params.house || params.number || params.customHouse || "";

		// clear
		const entrance = params.type === "favorite" ? params.entrance : "";
		newData.entrance = entrance || "";
		newData.polygon = "";
		newData.flat = "";
		// clear raw
		newData.raw.entrance = entrance || "";
		newData.raw.flat = "";

		// reset
		if (
			rowData.raw.settlement === newData.raw.settlement &&
			rowData.raw.street === newData.raw.street &&
			rowData.raw.streetType === newData.raw.streetType &&
			rowData.raw.name === newData.raw.name
		) {
			setOpen(false);
			debounceClose();
			return;
		}

		setData(newData);
		reset();
		debounceClose();
		if (!newData.raw.save) setInputValue(street);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const mapSearchOptions = useMemo<MapSearch.Options>(() => {
		const baseSearch: StreetBase.Props["searchTypes"] = searchTypes.length
			? searchTypes
			: ["street"];
		return {
			language,
			customerId,
			searchType: customerId ? ["favorite", ...baseSearch] : baseSearch,
			country: "ua",
			query: querySearch,
			near: point ? { point } : undefined,
			responseOrder: [
				"address",
				"street",
				"localObject",
				"object",
				"settlementDistrict",
				"settlement",
				"district",
				"region",
				"adminPlace",
				"adminLevel3",
				"road",
				"country",
			],
			responseOrderBySettlement: true,
			taxiServiceId,
		};
	}, [customerId, language, point, querySearch, searchTypes, taxiServiceId]);

	const mapSearchResult = useMapSearch(mapSearchOptions);

	useEffect(() => {
		if (mapSearchResult.loading) return;

		setResult(mapSearchResult);
	}, [mapSearchResult, setOpen]);

	useEffect(() => {
		if (result.loading) return;
		if (result.objects?.length) setActiveOption(0);
	}, [result]);

	const onSelect = useCallback(
		(option: Option<Map.Search.Object>) => {
			if (option) updatedData(option.value);
		},
		[updatedData],
	);

	const openOverlay = useMemo(() => open, [open]);

	const placeholder = useMemo(() => {
		if (rowIndex === 0) return pointOnMapFrom;
		return pointOnMapTo;
	}, [pointOnMapFrom, pointOnMapTo, rowIndex]);

	const propsInput = useMemo(() => {
		const text = streetShow(rowData.raw);
		return {
			placeholder: text || placeholder,
			value: inputValue !== null ? inputValue : text,
		};
	}, [inputValue, rowData, streetShow, placeholder]);

	const size = useMemo(
		() =>
			propsInput.value.length > 10 ? propsInput.value.length * 1.25 : 10,
		[propsInput.value.length],
	);

	return (
		<StyledPopup
			useClickControl
			useArrow={false}
			open={openOverlay}
			offset={offset}
			styles={styles}
			trackerId={dataKey + rowData.raw.id}
			containerId={containerId}
			onChangeOpen={setOpen}
			tracker={
				<StyledInput
					size={size}
					height={height}
					id={`${rowData.raw.id + dataKey}`}
					aria-label={dataKey}
					aria-rowindex={rowIndex}
					aria-colindex={colIndex}
					autoComplete="one-time-code"
					onChange={onChangeInputValue}
					onKeyUp={onEnterToInput}
					onKeyDown={onExistFromInput}
					onClick={onClickToInput}
					onFocus={onFocusToInput}
					onCut={(event) => {
						event.preventDefault();
					}}
					onDragOver={(event) => {
						event.preventDefault();
					}}
					onDrop={(event) => {
						event.preventDefault();
					}}
					onBlurCapture={onBlurCaptureToInput}
					{...propsInput}
				/>
			}
		>
			<OverlayStreet
				data={result}
				activeId={activeId}
				setActiveOption={setActiveOption}
				onSelect={onSelect}
				settlement={settlement?.settlement || rowData.raw.settlement}
				query={inputValue || ""}
			/>
		</StyledPopup>
	);
};

declare namespace StreetBase {
	interface Props extends PropsWithChildren {
		rowData: PointOrderModalTableBase.OrderModal;
		setData: (data: PointOrderModalTableBase.OrderModal) => void;
		/** Add a street to selection houses */
		addStreet: (street: Map.Search.Object | undefined, id: number) => void;
		addSettlement: (
			settlement: Map.Search.Object | undefined,
			id: number,
		) => void;
		addSettlementCoordinates: (
			settlement: LatLngLiteral | undefined,
			id: number,
		) => void;
		settlementCoordinates?: LatLngLiteral | undefined;
		/** Settlement with streets to selection */
		settlement?: Map.Search.Object;
		street?: Map.Search.Object;
		reset?: (id: number) => void;
		rowIndex?: number;
		dataKey: ValueAccessors | string;
		colIndex: number;

		styles?: Popup.Styles;
		offset?: Popup.Props["offset"];
		height?: number;
		customerId?: number;
		handleEventsOptions?: HandleEventsProps;
		containerId?: string;
		searchTypes?: Map.Search.Type[];
		taxiServiceId?: number;
	}
}

export const Street = memo(StreetBase);

export default StreetBase;
