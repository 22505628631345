// import moment from "moment";
import Rules from "../../pages/Settings/pages/Tariffs/tabs/Auto/components/Modal/components/Content/tabs/Main/components/Rules";

export const ruleFromResponse = (rule: any): Rules.Rule => ({
	...rule,
	priceZoneIds: rule.ruleToPriceZones.map(({ priceZone }) => priceZone.id),
	sectorIds: rule.ruleToSectors.map(({ sector }) => sector.id),
	carClassIds: rule.ruleToCarClasses.map(({ carClass }) => carClass.id),
	additionalFields: {
		...rule.additionalFields,
		validityTime: {
			...rule.additionalFields.validityTime,
			from: rule.additionalFields.validityTime.from,
			to: rule.additionalFields.validityTime.to,
			// from: millisecondsToDate(rule.additionalFields.validityTime.from),
			// to: millisecondsToDate(rule.additionalFields.validityTime.to),
		},
	},
});

export const ruleToRequest = (rule: Rules.Rule): any => ({
	id: rule.id,
	name: rule.name,
	active: rule.active,
	sectorIds: rule.sectorIds,
	carClassIds: rule.carClassIds,
	priceZoneIds: rule.priceZoneIds.filter((z) => z !== 0),
	position: rule.position ? new Date(rule.position) : new Date(),
	additionalFields: {
		...rule.additionalFields,
		validityTime: {
			...rule.additionalFields.validityTime,

			from: rule.additionalFields.validityTime.from,
			to: rule.additionalFields.validityTime.to,

			// from: stringTimeToMilliseconds(
			// 	rule.additionalFields.validityTime.from,
			// ),
			// to: stringTimeToMilliseconds(rule.additionalFields.validityTime.to),
		},
	},
});

// function stringTimeToMilliseconds(time: Date | number) {
// 	return moment(time).utc(true).diff(moment(time).utc(true).startOf("day"));
// }
// function millisecondsToDate(timestamp: number) {
// 	const newDate = new Date();
// 	newDate.setHours(0, 0, 0, 0);
// 	const time = moment(newDate).utc().add(timestamp).toDate();
// 	return time;
// }
