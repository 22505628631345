import { getPRPC } from "../../../hooks/usePRPC";
import createRPCQuery from "../../../utils/createRPCQuery.util";

interface ISendParams {
	action: number;
}

export default function sendSMSByOrder(id, params: ISendParams) {
	return async () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		const res = await createRPCQuery(
			() => prpcow?.theirsModel.orders.sms.send(id, params),
			{ silent: false, error: true, name: "orders.sms.send" },
		);
		console.log("[sendSMSByOrder]", { params, res });

		return res;
	};
}
