import makeTable from "./makeTable";

const accountsReport = makeTable({
	allIds: [
		"date",
		"operationType",
		"source",
		"order",
		"operationDescription",
		"user",
		"amountUah",
		"balanceUah",
	],
	i18tPath: (id) => `tableColumns.accounts.roles.${id}`,
	defaultIds: [
		"date",
		"operationType",
		"source",
		"order",
		"operationDescription",
		"user",
		"amountUah",
		"balanceUah",
	],
	initialSortMethod: {
		column: "date",
		type: "asc",
	},
	initialWidths: {
		date: 150,
		operationType: 150,
		source: 150,
		order: 150,
		operationDescription: 200,
		user: 150,
		amountUah: 150,
		balanceUah: 150,
	},
	initialResizable: {
		date: true,
		operationType: true,
		source: true,
		order: true,
		operationDescription: true,
		user: true,
		amountUah: true,
		balanceUah: true,
	},
	initialSortable: {
		date: true,
		operationType: true,
		source: true,
		order: true,
		operationDescription: true,
		user: true,
		amountUah: true,
		balanceUah: true,
	},
});

export default accountsReport;
