/* eslint-disable no-case-declarations */
/* eslint-disable prettier/prettier */
import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { CheckBox, Row, ToggleButton } from "uikit";
import { useTranslation } from "react-i18next";

import TableSettings from "../../../../../../../components/TableSettings";
import Language from "../../../../../../../services/Language";
import LightTable from "../../../../../../../components/LightTable";
import SMSTemplate from "../../../../../../../services/SMSTemplate";
import { TabKeys, ARR_ACCESS_PART_KEY } from "../../../constants/access";
import translationPath from "../../constants/translationPath";

import DefaultMark from "./components/DefaultMark";
import Root from "./components/Root";
import {
	Columns,
	columns,
	convertActionToString,
	defaultColumnsId,
} from "./constants";

const Content: React.FC<Content.Props> = ({
	value,
	selected,
	lang,
	setSelected,
	onEdit,
	saveHandler,
}) => {
	const { t } = useTranslation();

	// eslint-disable-next-line no-param-reassign
	columns.forEach(
		(col) => (col.label = t(`${translationPath}.headerCells.${col.id}`)),
	);
	const [columnsId, setColumnsId] = useState(defaultColumnsId);

	const updateSelected = useCallback(
		(template) => {
			if (selected.includes(template.id)) {
				setSelected(selected.filter((id) => template.id !== id));
			} else {
				setSelected([...selected, template.id]);
			}
		},
		[setSelected, selected],
	);

	const renderColumns = useMemo(
		() =>
			columnsId.map((columnId) => {
				const column = columns.find((col) => col.id === columnId);
				if (!column) return null;

				return (
					<LightTable.Column
						key={columnId}
						verticalAlign="middle"
						width={column.width || 150}
						flexGrow={column.width ? 0 : 1}
					>
						<LightTable.HeaderCell style={{ padding: "0 25px" }}>
							{t(`${translationPath}.headerCells.${column.id}`)}
						</LightTable.HeaderCell>
						<LightTable.Cell
							verticalAlign="middle"
							style={{ padding: "0 25px" }}
						>
							{(template) => {
								switch (columnId) {
									case Columns.ALLOWED:
										return (
											// <Row justify="center">
											<div
												onClick={(event) =>
													event.stopPropagation()
												}
												style={{
													maxWidth: "max-content",
												}}
											>
												<ToggleButton.ToggleButton
													value={template.active}
													onChange={(newActive) => {
														saveHandler(
															{
																...(template as SMSTemplate.Modified),
																active: newActive,
															},
															true,
															template as SMSTemplate.Modified,
														);
													}}
												/>
											</div>
											// </Row>
										);
									case Columns.AUTOMATIC_SENDING:
										return (
											// <Row justify="center">
											<div
												onClick={(event) =>
													event.stopPropagation()
												}
												style={{
													maxWidth: "max-content",
												}}
											>
												<ToggleButton.ToggleButton
													value={
														template.automaticSending
													}
													onChange={(
														newAutomatSend,
													) => {
														saveHandler(
															{
																...(template as SMSTemplate.Modified),
																automaticSending:
																	newAutomatSend,
															},
															true,
														);
													}}
												/>
											</div>
											// </Row>
										);
									case Columns.NAME:
										return (
											<Row gaps="5px">
												<span>{template.name}</span>
												{template.isDefault && (
													<DefaultMark>
														(default)
													</DefaultMark>
												)}
											</Row>
										);
									case Columns.ACTION:
										return convertActionToString[
											template.action
										];
									case Columns.COMPANIES:
										const companies: string[] =
											template.taxiServices.map(
												(taxiService) =>
													taxiService.company.name[
														lang
													],
											);
										const uniqueCompanies: string[] =
											Array.from(new Set(companies));

										return (
											<Row align="center" gaps="8px*">
												{uniqueCompanies.map(
													(company, index) => (
														<Row
															align="center"
															key={index}
														>
															{company}
															{index !==
																uniqueCompanies.length -
																	1 && ","}
														</Row>
													),
												)}
											</Row>
										);
									case Columns.BRANCHES:
										return (
											<Row align="center" gaps="8px*">
												{template.taxiServices?.map(
													(taxiService, index) => (
														<Row
															align="center"
															key={taxiService.id}
														>
															{
																taxiService
																	?.settlement[
																	lang
																]
															}
															{index !==
																template
																	.taxiServices
																	.length -
																	1 && ","}
														</Row>
													),
												)}
											</Row>
										);
									default:
										return null;
								}
							}}
						</LightTable.Cell>
					</LightTable.Column>
				);
			}),
		[columnsId, lang, saveHandler, t],
	);

	return (
		<Root sizes="1fr auto!">
			<LightTable
				data={value}
				virtualized
				headerHeight={44}
				rowHeight={44}
				fillHeight
				shouldUpdateScroll={false}
				rowClassName={(rowData) =>
					rowData && selected.includes(rowData.id) ? "selected" : ""
				}
				onRowClick={updateSelected}
				onRowDoubleClick={onEdit}
				accessName={ARR_ACCESS_PART_KEY[TabKeys.TEMPLATES_SMS]}
			>
				<LightTable.Column verticalAlign="middle" width={50}>
					<LightTable.HeaderCell style={{ padding: "0 25px" }}>
						<CheckBox
							value={
								selected.length === value.length &&
								value.length !== 0
							}
							onChange={(isAllSelected) => {
								if (isAllSelected) {
									setSelected(value.map(({ id }) => id));
								} else {
									setSelected([]);
								}
							}}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell
						verticalAlign="middle"
						style={{ padding: "0 25px" }}
					>
						{(template) => (
							<CheckBox
								value={selected.includes(template.id)}
								onChange={() => {
									updateSelected(template);
								}}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>
				{renderColumns}
			</LightTable>
			<TableSettings
				value={columnsId}
				defaultValue={defaultColumnsId}
				columns={columns}
				onChange={setColumnsId}
			/>
		</Root>
	);
};

declare namespace Content {
	interface Props {
		value: SMSTemplate.Model[] | [];

		selected: number[];
		setSelected: Dispatch<number[]>;
		lang: Language;
		onEdit?: Dispatch<SMSTemplate.Modified>;
		saveHandler: (
			template: SMSTemplate.New | SMSTemplate.Modified,
			force?: boolean,
			actualEditingItem?: SMSTemplate.Modified | null,
		) => Promise<void>;
	}

	type ColumnType = {
		id: string;
		label: string;
		width?: number;
		justify?: "center";
	};
}

export default Content;
