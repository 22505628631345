import React, { Dispatch, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RowDataType } from "rsuite-table";
import { CheckBox, theme, ToggleButton } from "uikit";
import { uniq } from "lodash";
import styled from "styled-components";

import Service from "../../../../../../../../services/Service";
import { Language } from "../../../../../../../../services";
import { useTypedDispatch } from "../../../../../../../../redux/store";
import { preferences } from "../../../../../../../../redux/reducers";
import updateService from "../../../../../../../../redux/services/Preferences/Services/updateService";
import returnServices from "../../../../../../../../redux/services/Preferences/Services/returnServices";
import { SVGgeneral } from "../../../../../../../../utils/generalSprite";
import {
	ColumnId,
	ColumnIds,
} from "../../../../../../../../constants/tables/types";
import LightTable, {
	useTableOptions,
	UseTableOptions,
} from "../../../../../../../../components/LightTable";
import {
	CompaniesByIds,
	StyledGridItem,
	TaxiServicesByIds,
} from "../../../../../../../../components/common";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

const StyledCell = styled.div`
	color: ${(props: { active: boolean }) =>
		props.active ? theme.colors.primary : "lightgray"} !important;
	${theme.longWord}
`;

const Content: React.FC<Content.Props> = ({
	columns,
	data,
	selected,
	onChangeSelected,
	languageFilter,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onDelete,
	onEdit,
	onChangeTable,
	editorTable,
}) => {
	const { t } = useTranslation();
	const { sort, onSort, tableRef, onKeyUp, onKeyDown, ctrlPressed } =
		useTableOptions({
			value: editorTable,
			setValue: onChangeTable,
		});

	const [loading, setLoading] = React.useState(false);
	const dispatch = useTypedDispatch();

	const tableHeight = 700;

	const handleSortColumn = useCallback(
		(sortColumn, sortType) => {
			const promise: any = dispatch(
				returnServices({ sortType, sortColumn, limit: data.length }),
			);

			promise.then((result) => {
				setLoading(false);
				dispatch(preferences.services.data.setServices(result));
			});

			onSort(sortColumn, sortType);
		},
		[data.length, dispatch, onSort],
	);
	const loadMore = useCallback(() => {
		setLoading(true);
		const promise: any = dispatch(
			returnServices({ offset: data.length, limit: 50 }),
		);

		promise.then((result) => {
			dispatch(
				preferences.services.data.setServices([...data, ...result]),
			);
			setLoading(false);
		});
	}, [data, dispatch]);

	const handleScroll = useCallback(
		(x, y) => {
			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			if (contextHeight - top - tableHeight < 300) {
				loadMore();
			}
		},
		[data.length, loadMore],
	);

	const updateSelected = useCallback(
		(item: RowDataType<Service.Model>, force = false) => {
			const model = item as Service.Model;

			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					onChangeSelected(selected.filter((id) => model.id !== id));
				} else {
					onChangeSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? onChangeSelected([])
					: onChangeSelected([model.id]);
			}
		},
		[ctrlPressed, onChangeSelected, selected],
	);

	const renderColumn = useCallback(
		(column: ColumnId<"service">) => {
			switch (column) {
				case "checkbox":
					return (
						<LightTable.Column width={64}>
							<LightTable.HeaderCell verticalAlign="middle">
								<CheckBox
									value={
										data.length
											? selected.length === data.length
											: false
									}
									onChange={(value) =>
										onChangeSelected(
											value
												? data.map((item) => item.id)
												: [],
										)
									}
								/>
							</LightTable.HeaderCell>
							<LightTable.Cell>
								{(rowData) => (
									<CheckBox
										value={selected.includes(rowData.id)}
										onChange={() => {
											updateSelected(rowData, true);
										}}
									/>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "active":
					return (
						<LightTable.Column flexGrow={1} sortable resizable>
							<LightTable.HeaderCell
								verticalAlign="middle"
								align="center"
							>
								{t(
									"pages.preferencesPages.screenDirectory.services.content.str200",
								) ?? ""}
								.
							</LightTable.HeaderCell>
							<LightTable.Cell
								dataKey={column}
								fullText
								style={{
									display: "flex",
									justifyContent: "center",
								}}
							>
								{(rowData) => (
									<ToggleButton.ToggleButton
										value={rowData?.active}
										onChange={(value) => {
											const promise: any = dispatch(
												updateService({
													...rowData,
													active: value,
												}),
											);
											promise.then((result) => {
												dispatch(
													preferences.services.data.setServices(
														data.map((item) =>
															item.id ===
															result.id
																? result
																: item,
														),
													),
												);
											});
										}}
									/>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "name":
					return (
						<LightTable.Column flexGrow={1.5} sortable resizable>
							<LightTable.HeaderCell
								verticalAlign="middle"
								fullText
							>
								{t(
									"pages.preferencesPages.screenDirectory.services.content.str150",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell dataKey={column}>
								{(rowData) => (
									<StyledCell active={rowData?.active}>
										{rowData?.name[languageFilter]}
									</StyledCell>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "company":
					return (
						<LightTable.Column flexGrow={1} sortable resizable>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.services.content.str151",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell dataKey="company" fullText>
								{(rowData) => (
									<StyledCell active={rowData.active}>
										<CompaniesByIds
											taxiServiceIds={uniq(
												rowData?.serviceToTaxiServices?.map(
													({ taxiService }) =>
														taxiService.id,
												) || [],
											)}
											lang={languageFilter}
										/>
									</StyledCell>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "branch":
					return (
						<LightTable.Column flexGrow={1} sortable resizable>
							<LightTable.HeaderCell verticalAlign="middle">
								{t(
									"pages.preferencesPages.screenDirectory.services.content.str152",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell dataKey="taxiService" fullText>
								{(rowData) => (
									<StyledCell active={rowData.active}>
										<TaxiServicesByIds
											taxiServiceIds={uniq(
												rowData?.serviceToTaxiServices?.map(
													({ taxiService }) =>
														taxiService.id || 0,
												) || [],
											)}
											lang={languageFilter}
										/>
									</StyledCell>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "isExecutorRelated":
					return (
						<LightTable.Column flexGrow={1} resizable sortable>
							<LightTable.HeaderCell
								verticalAlign="middle"
								align="center"
							>
								{t(
									"pages.preferencesPages.screenDirectory.services.content.str153",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell
								dataKey="availableForExecutor"
								fullText
							>
								{(rowData) => (
									<StyledCell
										active={rowData?.active}
										style={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										{rowData.availableForExecutor ? (
											<SVGgeneral id="approved" />
										) : (
											<SVGgeneral id="denied" />
										)}
									</StyledCell>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				case "isVehicleRelated":
					return (
						<LightTable.Column flexGrow={1} resizable sortable>
							<LightTable.HeaderCell
								verticalAlign="middle"
								align="center"
							>
								{t(
									"pages.preferencesPages.screenDirectory.services.content.str154",
								) ?? ""}
							</LightTable.HeaderCell>
							<LightTable.Cell dataKey="availableForCar" fullText>
								{(rowData) => (
									<StyledCell
										active={rowData?.active}
										style={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										{rowData.availableForCar ? (
											<SVGgeneral id="approved" />
										) : (
											<SVGgeneral id="denied" />
										)}
									</StyledCell>
								)}
							</LightTable.Cell>
						</LightTable.Column>
					);

				default:
					return <></>;
			}
		},
		[
			data,
			dispatch,
			updateSelected,
			languageFilter,
			onChangeSelected,
			selected,
			t,
		],
	);

	return (
		<StyledGridItem
			area="table"
			w="100%"
			h="100%"
			tabIndex={-1}
			onKeyDown={onKeyDown}
			onKeyUp={onKeyUp}
		>
			<LightTable
				ref={tableRef}
				data={data}
				virtualized
				fillHeight
				headerHeight={44}
				rowHeight={44}
				// hea
				onRowClick={updateSelected}
				onRowDoubleClick={onEdit}
				accessName={ARR_ACCESS_PART_KEY[TabKeys.SERVICES]}
				sortColumn={sort.dataKey}
				sortType={sort.sortType}
				onSortColumn={handleSortColumn}
				loading={loading}
				onScroll={handleScroll}
				shouldUpdateScroll={false}
				rowClassName={(rowData) =>
					rowData && selected.includes(rowData.id) ? "selected" : ""
				}
			>
				{columns.map((column) => renderColumn(column))}
			</LightTable>
		</StyledGridItem>
	);
};

declare namespace Content {
	interface Props {
		columns: ColumnIds<"service">;
		data: any[];
		selected: number[];
		onDelete: (id) => void;
		onEdit: (value) => void;
		languageFilter: Language;
		onChangeSelected: Dispatch<number[]>;

		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}
}

export default Content;
