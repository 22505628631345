import React, { useMemo, useCallback } from "react";
import { isFunction, isNil, isUndefined, isString } from "lodash";
import PropsOf from "uikit/src/types/PropsOf";

import { useKeyBindSettings } from "../../../../../../../../../../hooks/useKeyBindSettings";
import { BaseKeybindEntries } from "../../../../../../../../../../redux/reducers/settings/keybinds";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import keyNames from "../../../../../../../../../../utils/keyNames";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../../../access";

import Root from "./components/Root";

const Button: React.FC<Button.Props> = ({
	settingsKeyId,
	accessName,
	...props
}) => {
	const ACCESS_SECTION = AccessKey.ORDERS;

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const isNotAccess = useMemo(
		() =>
			accessName
				? !hasAccess(
						generateAccessName(ACCESS_SECTION, accessName),
						personalRole,
				  )
				: false,
		[ACCESS_SECTION, accessName, personalRole],
	);

	useKeyBindSettings(settingsKeyId, (event) => {
		if (!isNotAccess) {
			if (!props.disabled) props.onClick?.();

			event.preventDefault();
			event.stopPropagation();
		}
	});

	const keys = useTypedSelector((state) =>
		isNil(settingsKeyId)
			? []
			: state.settings.keybinds[settingsKeyId].map(
					(key) => keyNames[key],
			  ),
	);

	const keysString = useMemo(
		() => (keys.length ? `(${keys.join("+")})` : ""),
		[keys],
	);

	const cleanStringFromBadSigns = useCallback(
		(str: string | undefined): string | undefined => {
			if (!isString(str)) return undefined;

			return str.trim().replace(/\($/, "");
		},
		[],
	);

	const title = useMemo(
		() => {
			if (isFunction(props.title)) return props.title(keys);

			if (isUndefined(props.title)) return undefined;

			return `${props.title}${
				!isUndefined(props.title) ? " " : ""
			}${keysString}`;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[keys, keysString, props.title],
	);

	if (isNotAccess) return null;

	return <Root {...props} title={cleanStringFromBadSigns(title)} />;
};

declare namespace Button {
	type Variant = Root.Variant;

	interface Props extends Omit<PropsOf<typeof Root>, "title" | "onClick"> {
		settingsKeyId?: keyof BaseKeybindEntries<"meta">;
		accessName?: AccessKey;

		title?: ((keys: string[]) => string) | string;

		onClick?: VoidFunction;
	}

	namespace Variant {
		type Props = Root.Variant.Props;
	}
}

export default Button;
