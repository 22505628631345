import makeTable from "./makeTable";

const cashlessOrdersPeriodReport = makeTable({
	allIds: [
		"erpou",
		"account",
		"companyName",
		"balance27062024",
		"replenishmentUah",
		"orderCount",
		"orderAmountUah",
		"balance04072024",
		"periodBalanceUah",
	],
	i18tPath: (id) => `tableColumns.accounts.roles.${id}`,
	defaultIds: [
		"erpou",
		"account",
		"companyName",
		"balance27062024",
		"replenishmentUah",
		"orderCount",
		"orderAmountUah",
		"balance04072024",
		"periodBalanceUah",
	],
	initialSortMethod: {
		column: "balance27062024",
		type: "asc",
	},
	initialWidths: {
		erpou: 120,
		account: 120,
		companyName: 150,
		balance27062024: 150,
		replenishmentUah: 150,
		orderCount: 150,
		orderAmountUah: 150,
		balance04072024: 150,
		periodBalanceUah: 150,
	},
	initialResizable: {
		erpou: true,
		account: true,
		companyName: true,
		balance27062024: true,
		replenishmentUah: true,
		orderCount: true,
		orderAmountUah: true,
		balance04072024: true,
		periodBalanceUah: true,
	},
	initialSortable: {
		erpou: true,
		account: true,
		companyName: true,
		balance27062024: true,
		replenishmentUah: true,
		orderCount: true,
		orderAmountUah: true,
		balance04072024: true,
		periodBalanceUah: true,
	},
});

export default cashlessOrdersPeriodReport;
